<template>
  <div :style="colors">
    <a ref="skipLink" class="skip-link screen-reader-text" href="#main">Skip to content</a>
    <Alert ref="cg-alert" :type="alertVariant" :title="alertTitle" />
    <Loader :show="showLoader"/>

    <div class="user-sidebar-wrapper hidden lg:block">
      <div v-if="loaded" class="user-sidebar">
        <div class="user-sidebar-actions">

          <!-- User notifications -->
          <Notifications :notifications="notifications" />

          <!-- User profile menu -->
          <ProfileMenu menu-placement="bottom-end" :display-name="userInitials" show-indicator>
            <div class="m-2 text-center">
              <span v-if="$impersonating" class="ml-1 font-thin block text-xs">{{impersonator}}</span>
              <span class="ml-1 font-bold block">{{userName}}</span>
            </div>
            <hr />

            <!-- TODO: user roles should contain all roles (for example both 'manager' and 'student' roles) -->
            <div v-if="$company.roles.length>1">
              <span class="ml-2 mt-2 font-bold">{{$t('Navbar.Roles')}}</span>
              <MenuItem
                v-for="(r, i) in $company.roles" :key="i"
                icon-name="restart"
                :weight="($profile.currentRole === r.name)? 'bold':''"
                @click="setCurrentRole(r)"
              >
                {{ r.parent_id? r.label : $roleMap[$methods.getBaseRole(r.name)].label }}
              </MenuItem>
              <hr role="separator" />
            </div>
            <MenuItem to="/profile" icon-name="settings">{{ $t('Navbar.Profile') }}</MenuItem>
            <MenuItem v-if="$impersonating" icon-name="user"  color="accent" @click="$stopImpersonation">{{ $t('Account.StopImpersonate') }}</MenuItem>
            <hr role="separator" />
            <MenuItem icon-name="logout" @click="logOut">{{ $t('Navbar.LogOut') }}</MenuItem>
          </ProfileMenu>
        </div>
      </div>
    </div>

    <PageWrapper id="main" role="main" class="bg-extra-light">
      <template v-if='loaded' #navbar>
        <Navbar ref='navbarRef' :footnote="$company.created_by_company.customization_enabled ? $company.created_by_company.displayname : ($company.customization_enabled ? $company.displayname : $t('General.Cyberguru'))" :logoLinkTo="$getRoleBaseRoute()" @hook:mounted="setPageTitle()">
          <template #logo>
            <Logo />
          </template>
          <template #logo-small>
            <LogoSymbol />
          </template>
          <template #main-menu>
            <!--DASHBOARD-->
            <NavbarItem :aria-label="$t('Navbar.Dashboard')" :active="isActiveItem(roleRoute)" :to='roleRoute' icon='home'>
              {{ $t('Navbar.Dashboard') }}
            </NavbarItem>
            <!--PROGRESSI-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.Student)" :active="isActiveItem(roleRoute+'/progress')"
              :aria-label="$t('Navbar.Progress')" :to="roleRoute+'/progress'" icon='analytics'>
              {{ $t('Navbar.Progress') }}
            </NavbarItem>
            <!--PIANO FORMATIVO-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.Student) && hasAwarenessOrChannel"
              :aria-label="$t('Navbar.Plan')" :active="isActiveItem(roleRoute+'/modules')" :to="roleRoute+'/modules'" icon='courses'>
              {{ $t('Navbar.Plan') }}
            </NavbarItem>
            <!--GESTIONE COMPANY SOLO PER CSM, ADMIN, MSP-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.CSM, Roles.Admin, Roles.MSP) && $checkPermission(['frontend-view-company, admin-view-all-company', 'frontend-view-company, admin-view-company'])"
              :aria-label="$t('Navbar.CompanyManagement')" :active="isActiveItem(roleRoute+'/company')" :to="roleRoute+'/company'" icon='list'>
              {{ $t('Navbar.CompanyManagement') }}
            </NavbarItem>
            <!-- CONFIGURAZIONE COMPANY INFO/SETUP: solo per companyAdmin -->
            <NavbarItem
                v-if="$profile.hasRoles(Roles.CompanyAdmin) && $checkPermission(['frontend-view-company, admin-view-company'])"
                :aria-label="$t('Navbar.CompanyManagement')" :active="isActiveItem(roleRoute+'/company')" :to="roleRoute+'/company/'+$user.company_id" icon='list'>
              {{ $t('Navbar.CompanyManagement') }}
            </NavbarItem>
            <!-- ALL ACCOUNTS LIST -->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.CSM, Roles.Admin, Roles.MSP, Roles.ContentManager) && $checkPermission(['admin-view-all-account, frontend-view-account-mgmt', 'admin-view-company-account, frontend-view-account-mgmt'])"
              :aria-label="$t('Navbar.AccountManagement')" :active="isActiveItem(roleRoute+'/account')" :to="roleRoute+'/account'" icon='user'>
              {{ $t('Navbar.AccountManagement') }}
            </NavbarItem>
            <!--STATISTICHE UTENTI: UTILIZZA ICONA "userStats"-->
            <NavbarItem
                v-if="$profile.hasRoles(Roles.Supervisor,Roles.CompanyAdmin) && $checkPermission(['frontend-view-user-stats, reporting-view-company-users-stats']) && hasAwarenessOrChannel"
                :aria-label="$t('Navbar.UsersStats')" :active="isActiveItem(roleRoute+'/stats')" :to="roleRoute+'/stats/'+$profile.company_id" icon='userStats'>
              {{ $t('Navbar.UsersStats') }}
            </NavbarItem>
            <NavbarItem
                v-if="$profile.hasRoles(Roles.Teamleader) && $checkPermission(['frontend-view-user-stats, reporting-view-team-users-stats']) && hasAwarenessOrChannel"
                :aria-label="$t('Navbar.UsersStats')" :active="isActiveItem(roleRoute+'/stats')" :to="roleRoute+'/stats/'+$profile.company_id+'/'+$profile.team" icon='userStats'>
              {{ $t('Navbar.UsersStats') }}
            </NavbarItem>
            <!--STATISTICHE-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.Teamleader,Roles.Supervisor,Roles.CompanyAdmin, Roles.CSM, Roles.Admin, Roles.MSP) && reportPerm"
              :aria-label="$t('Navbar.ReportOverview')" :active="isActiveItem(roleRoute+'/report')" :to="roleRoute+'/report'" icon='chart'>
              {{ $t('Navbar.ReportOverview') }}
            </NavbarItem>
            <!--MATERIALE FORMATIVO-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.CSM, Roles.Admin, Roles.MSP, Roles.ContentManager) && contentMgmtPerm"
              :aria-label="$t('Navbar.ContentManagement')" :active="isActiveItem(roleRoute+'/content-management')" :to="roleRoute+'/content-management'"
              icon='courses'>
              {{ $t('Navbar.ContentManagement') }}
            </NavbarItem>
            <!--MATERIALE FORMATIVO: SOLO RESERVED AREA E TEMPLATE (companyAdmin)-->
            <NavbarItem
                v-if="$profile.hasRoles(Roles.CompanyAdmin) && contentMgmtPerm"
                :aria-label="$t('Navbar.ContentManagement')" :active="isActiveItem(roleRoute+'/content-management/reserved')" :to="roleRoute+'/content-management/reserved'"
                icon='courses'>
              {{ $t('Navbar.ContentManagement') }}
            </NavbarItem>
            <!--GESTIONE RILASCI-->
            <NavbarItem
              v-if="( $profile.hasRoles(Roles.CSM, Roles.Admin, Roles.MSP) || ($profile.hasRoles(Roles.CompanyAdmin) && $profile.hasService(ServiceType.Phishing)) ) && releaseMgmtPerm"
              :aria-label="$t('Navbar.ReleaseManagement')" :active="isActiveItem(roleRoute+'/release-management')" :to="roleRoute+'/release-management'" icon='calendar'>
              {{ $t('Navbar.ReleaseManagement') }}
            </NavbarItem>
            <!-- TODO Should 'awareness-view-all-certificate-item-menu' check be removed after certificates are ready? -->
            <!--CERTIFICATI-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.Student,Roles.Supervisor,Roles.CompanyAdmin) && $checkPermission(['awareness-view-all-certificate-item-menu'])"
              :aria-label="$t('Navbar.Certificates')" :active="isActiveItem(roleRoute+'/certificates')" :to="roleRoute+'/certificates'" icon='badge'>
              {{ $t('Navbar.Certificates') }}
            </NavbarItem>
            <!--ACKNOWLEDGEMENT-->
            <NavbarItem
                v-if="$profile.hasRoles(Roles.Student)" :aria-label="$t('Navbar.Acknowledgments')" :active="isActiveItem(roleRoute+'/acknowledgment')"
                :to="roleRoute+'/acknowledgment'" icon='shield'>
              {{ $t('Navbar.Acknowledgments') }}
            </NavbarItem>
            <!--AREA RISERVATA-->
            <NavbarItem
              v-if="$profile.hasRoles(Roles.Student)" :aria-label="$t('Navbar.ReservedArea')" :active="isActiveItem(roleRoute+'/reserved-area')"
              :to="roleRoute+'/reserved-area'" icon='file'>
              {{ $t('Navbar.ReservedArea') }}
            </NavbarItem>
          </template>
          <template #footer-menu>
            <NavbarItem v-if='$company.roles.length>1' :aria-label="$t('Navbar.Roles')" class='block lg:hidden' to='#'>
              <span>{{ $t('Navbar.Roles') }}</span>
              <MenuItem
                v-for='(r, i) in $company.roles' :key='i' :weight="($profile.currentRole === r.name)? 'bold':''"
                icon-name='restart' @click='setCurrentRole(r)'>
                {{ r.parent_id? r.label : $roleMap[$methods.getBaseRole(r.name)].label }}
              </MenuItem>
            </NavbarItem>
            <NavbarItem :aria-label="$t('Navbar.Profile')" class='block lg:hidden' icon='user' to='#'>
              {{ $t('Navbar.Profile') }}
            </NavbarItem>
            <NavbarItem v-if="$profile.hasRoles(Roles.Admin)" :active="isActiveItem(roleRoute+'/settings')" :aria-label="$t('Navbar.Settings')"
                        :to="roleRoute+'/settings'" icon='settings'>{{ $t('Navbar.Settings') }}
            </NavbarItem>
            <!--HELP-->
            <NavbarItem :aria-label="$t('Navbar.Help')" :active="isActiveItem('/help')" icon='help' to='/help'>
              {{ $t('Navbar.Help') }}
            </NavbarItem>
            <NavbarItem :aria-label="$t('Navbar.LogOut')" icon='logout' @click='logOut'>{{ $t('Navbar.LogOut') }}</NavbarItem>
          </template>
          <template #title>
            <Icon :name='navbarPageIcon' />
            <span class="truncate mt-1">{{ navbarPageTitle }}</span>
          </template>
          <template #notifications>
            <Notifications :notifications='notifications' />
          </template>
        </Navbar>
      </template>

      <router-view v-if='loaded'></router-view>
    </PageWrapper>
  </div>
</template>

<script>
import Vue from 'vue'
import accountService from '@/services/account.service.js'
import companyService from '@/services/company.service.js'
import { EventType, OptionDataType, Roles, RolesScale, ServiceType, CyberGuru } from '@/common/constants'
import { defaultPaths } from '@/router/routes'
import userService from '@/services/user.service'
import localizationService from '@/services/localization.service'
import awarenessService from '@/services/awareness.service'
import router from '@/router/router'
import colors from '@/utils/colors'

const NOTIFICATION_PERIOD=process.env.NOTIFICATION_PERIOD || 30000; // 30 sec

export default {
  data() {
    return {
      language: null,
      userCompany: null,
      navbarPageTitle: '',
      navbarPageIcon: '',
      alertTitle: "",
      alertVariant: 'success',
      notifications: [],
      activeItem: '',
      roleRoute: '',
      loaded: null,
      ServiceType,
      Roles,
      colors: {},
      primaryColor: '',
      secondaryColor: '',
      contrastPrimary: '',
      contrastSecondary: '',
      showLoader: false
    };
  },
  beforeMount() {
    const isProd = "_PRODUCTION"
    if (isProd === "true") {
      console.log = () => {}
      console.info = () => {}
      console.debug = () => {}
    }

    this.$eventBus.$on('logout', this.logOut);

    this.$eventBus.$on('notification-click', (id) => {
      const index = this.notifications.findIndex((n) => n.id === id);
      if(index > -1) {
        this.markNotificationAsRead(this.notifications[index], index);

        let link = this.notifications[index].link;
        if (link) this.$router.push(link)
      }
    });

    this.$eventBus.$on('notification-set-all-read', () => {
      this.notifications.forEach((n, index) => this.markNotificationAsRead(n,index))
    })

    this.$eventBus.$on('show-alert', (event) => {
      this.alertTitle = event.title;
      this.alertVariant = event.variant;
      this.$refs['cg-alert'].showAlert();
    });

    this.$eventBus.$on('loading', (value) => this.showLoader = value)

    this.$eventBus.$on('route-changed', (path) => {
      // set role route so that the navbar menu is updated
      let rRoute = path.split('/').filter(i=>i)
      let basePath = rRoute[0] || ''
      this.roleRoute = (!this.$getRoleBaseRoute || !defaultPaths.find(e => e == `/${basePath}`))? `/${basePath}` : this.$getRoleBaseRoute()
      // set current path as active item (joining the elements removes last slash of the path, required for validation of the active menu item)
      this.activeItem = '/' + rRoute.join('/')
      if (this.$refs.navbarRef) this.$refs.navbarRef.menuOpen = false;
      this.$nextTick(() => { // aspettiamo che il DOM abbia finito il render
        if (this.$refs.navbarRef) {
          this.setPageTitle();
          //this.$refs["skipLink"].focus();
        }
      });
    });

    this.$eventBus.$on('current-company', this.setCurrentCompany);
    this.$eventBus.$on('load-user', this.loadUser);

  },
  beforeDestroy() {
    this.$eventBus.$off('loading');
    this.$eventBus.$off('show-alert');
    this.$eventBus.$off('notification-set-all-read');
    this.$eventBus.$off('notification-click');
    this.$eventBus.$off('route-changed');
    this.$eventBus.$off('logout');
    this.$eventBus.$off('current-company');
    this.$eventBus.$off('load-user');
  },
  computed: {
    releaseMgmtPerm() {
      return this.$checkPermission([
        "phishing-view-all-campaign",
        "phishing-view-company-campaign",
        "phishing-view-all-remediation",
        "phishing-view-company-remediation"
      ])
    },
    userInitials() {
      if (!this.$profile) return '';

      return (this.$profile.firstname && this.$profile.lastname)?
        (this.$profile.firstname.substr(0,1) + this.$profile.lastname.substr(0,1)).toUpperCase() :
        (this.$profile.username.substr(0,2).toUpperCase());
    },
    userName() {
      if (!this.$profile) return '';
      return (this.$profile.firstname && this.$profile.lastname)? (this.$profile.firstname + ' ' + this.$profile.lastname) : this.$profile.username;
    },
    impersonator(){
      if (!this.$user || !this.$user.impersonator) return this.$t('Account.Impersonator');
      return (this.$user.impersonator.firstname && this.$user.impersonator.lastname)? `${this.$user.impersonator.firstname}  ${this.$user.impersonator.lastname}` : this.$t('Account.Impersonator');
    },
    contentMgmtPerm () {
      return this.$checkPermission('frontend-view-content-mgmt') && 
        this.$checkPermission([
          'awareness-view-all-modules',
          'awareness-view-company-modules',
          'awareness-view-all-levels',
          'awareness-view-company-levels',
          'awareness-view-all-reserved',
          'awareness-view-company-reserved',
          'badge-view-all-badge-classes',
          'badge-view-company-badge-classes',
          'phishing-view-all-template',
          'phishing-view-template',
          'phishing-view-all-landingpage',
          'phishing-view-landingpage',
        ])
    },
    canViewReportOverview () {
      return this.$checkPermission([
        'reporting-view-all-service-participation',
        'reporting-view-company-service-participation',
        'reporting-view-account-service-participation',
        'reporting-view-all-account-team-rank',
        'reporting-view-company-account-team-rank',
        'reporting-view-team-account-team-rank',
        'reporting-view-account-account-team-rank',
        'reporting-view-all-account-global-rank',
        'reporting-view-company-account-global-rank',
        'reporting-view-account-account-global-rank',
        'reporting-view-all-team-rank',
        'reporting-view-company-team-rank',
        'reporting-view-team-team-rank',
        'reporting-view-account-team-rank',
      ])
    },
    canViewReportAwareness () {
      return this.$checkPermission([
        'reporting-view-all-module-times',
        'reporting-view-company-module-times',
        'reporting-view-team-module-times',
        'reporting-view-all-module-scores',
        'reporting-view-company-module-scores',
        'reporting-view-team-module-scores',
        'reporting-view-all-users-status',
        'reporting-view-company-users-status',
        'reporting-view-team-users-status',
        'reporting-view-all-stats',
        'reporting-view-company-stats',
        'reporting-view-team-stats',
      ])
    },
    canViewReportChannel () {
      return this.$checkPermission([
        'reporting-view-all-module-times',
        'reporting-view-company-module-times',
        'reporting-view-team-module-times',
        'reporting-view-all-users-status',
        'reporting-view-company-users-status',
        'reporting-view-team-users-status',
        'reporting-view-all-stats',
        'reporting-view-company-stats',
        'reporting-view-team-stats',
      ])
    },
    canViewReportPhishing () {
      return this.$checkPermission([
        'phishing-view-campaign-summary,phishing-view-all-company',
        'phishing-view-campaign-summary,phishing-view-company',
        'phishing-view-campaign-summary,phishing-view-all-campaign',
        'phishing-view-campaign-summary,phishing-view-company-campaign',
      ])
    },
    reportPerm() {
      return this.canViewReportOverview ||
        this.canViewReportAwareness ||
        this.canViewReportChannel ||
        this.canViewReportPhishing
    },
    hasAwarenessOrChannel () {
      return this.$profile.hasService(ServiceType.Awareness) || this.$profile.hasService(ServiceType.Channel);
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    async loadUser(callbackFn) {
      this.$eventBus.$emit('loading', true)

      try {

        //prototype dei timezone
        Vue.prototype.$timezones = Intl.supportedValuesOf('timeZone')
            .reduce((prev, el) => ({
              ...prev, [el.replace(' ', '')]: {
                id:   el,
                name: el,
              },
            }), {
              data:     Intl.supportedValuesOf('timeZone'),
              labels:   () => this.$timezones.data.map(el => ({ label: el, value: el })),
            })

        let responses = await Promise.all([
          userService.getUser(),
          companyService.getServicesList(),
          localizationService.getLanguages(),
        ])
        const [userRes, servicesRes, languagesRes] = responses

        Vue.prototype.$user     = userRes.data
        Vue.prototype.$services = servicesRes.data
          .reduce((prev, el) => ({
            ...prev, [el.name.replace(' ', '')]: {
              id:   el.service_id,
              name: el.name,
            },
          }), {
            data:     servicesRes.data,
            all:      () => this.$services.data.map(el => ({ id: el.service_id, name: el.name })),
            service:  (id) => this.$services.data.find(el => el.service_id === +id).name,
            labels:   (ids = []) => this.$services.data.filter(el => ids.includes(el.service_id))
              .map(el => ({ label: el.name, value: el.service_id })),
            fromName: (serviceName) => this.$services.data
              .find(el => el.name.toLowerCase() === serviceName.toLowerCase()).service_id,
            color:    (id) => colors.services[id],
          })

        responses = await Promise.all([
          accountService.getSingleAccount(this.$user.id, this.$user.company_id),
          companyService.getCompany(this.$user.company_id, true),
        ])
        const [profileRes, companyRes] = responses

        Vue.prototype.$profile  = Vue.observable({ ...profileRes.data, origLicenseByService: profileRes.data.licenseByService })
        Vue.prototype.$company  = {
          ...companyRes.data,
          roles: this.$user.companies.find(c=>c.company_id==this.$user.company_id).roles
            .sort((a, b) => RolesScale.indexOf(this.$methods.getBaseRole(a.name)) - RolesScale.indexOf(this.$methods.getBaseRole(b.name))) }

        if (!this.$profile.companies || !this.$profile.companies.length) throw new Error('User does not have any company')

        router.app.$i18n.locale = this.$profile.language || this.$company.default_language || 'it'
        //update $roleMap in the right language
        Object.keys(this.$roleMap).forEach((r) => this.$roleMap[r].label = this.$t('Roles.RoleNames.' + r))

        let h = document.querySelector('html');
        h.setAttribute('lang', router.app.$i18n.locale);

        // set title and favicon
        document.title = this.$company.created_by_company.customization_enabled ? this.$company.created_by_company.displayname : (this.$company.customization_enabled ? this.$company.displayname : CyberGuru.Name);

        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = this.$company.created_by_company.customization_enabled ? this.$company.created_by_company.togglelogo : (this.$company.customization_enabled ? this.$company.togglelogo : '/favicon.png');
        document.head.appendChild(link);
        
        // set languages once the locale has been set
        let langArr = [], langObj = {}
        languagesRes.data.forEach(lang => {
          langArr.push({ value: lang.lang_code, label: lang.name})
          langObj[lang.lang_code] = lang.name
        })

        Vue.prototype.$languages    = langArr
        Vue.prototype.$languagesObj = langObj

        this.setCompanyOptions(this.$company);

        Vue.prototype.$currentCompany = Vue.observable({...this.$company})

        this.$profile.hasRoles   = (...args) => [...args].some(id => this.$methods.isRole(this.$profile.currentRole, id))
        this.$profile.hasService = (...args) => [...args].every(id => this.$profile?.licenseByService.includes(id))

        await this.setCgaType()

        await this.setCurrentRole()

        // load all notifications and set interval for loading them periodically
        this.getNotifications();
        setInterval(this.getLastUnreadNotification, NOTIFICATION_PERIOD)

        this.calcColors();

        /**
         * Permissions param is a string or an array of strings, where at lease one element of the array should be valid
         * (OR logical operator), instead the permissions inside a single element (split by comma) of the array should be all valid (AND logical operator)
         * @param permissions array|string
         * @return boolean
         */
        Vue.prototype.$checkPermission = (permissions) => {
          if (!permissions || !this.$profile) return false

          return (typeof permissions == 'string' ? [permissions] : permissions)
            .some(p => p.split(',').every((i) => this.$profile.currentPermissions.includes(i.trim())))
        }

        Vue.prototype.$getRoleBaseRoute = () => {
          const role = this.getRoleObj();

          return role?.baseRoute || ''
        }

        Vue.prototype.$getFlatTags = (tags, resArr) => {
          const ORGS_KEYS = ['label', 'team', 'value', 'show', 'filter', 'order', 'key']

          if (!resArr) resArr = []
          for (let key in tags) {
            if (ORGS_KEYS.includes(key)) continue

            // add tag and keep looking for the other tags in the tree
            resArr.push({ ...tags[key], 'key': key })
            resArr = this.$getFlatTags(tags[key], resArr)
          }
          return resArr
        }

        Vue.prototype.$impersonating = localStorage.getItem('mask') ? localStorage.getItem('mask') : null

        Vue.prototype.$stopImpersonation = () => {
          localStorage.removeItem('mask')
          localStorage.removeItem('activeRole')
          this.$router.push('/').then(() => { location.reload() })
        }

        Vue.prototype.$startImpersonation = (account_uuid) => {
          if (account_uuid) {
            localStorage.setItem('mask', account_uuid)
            localStorage.removeItem('activeRole')
            location.reload()
          }
        }
      } catch (e) {
        this.logOut(e)
      }

      this.loaded = true
      this.$eventBus.$emit('loading', false)

      if (callbackFn) callbackFn();
    },
    calcColors() {
      this.primaryColor = this.getRGBColor(this.$company.company_options.primary_color.value, "primary");
      this.secondaryColor = this.getRGBColor(this.$company.company_options.secondary_color.value, "secondary");
      this.a11yColorPrimary = this.getRGBColor(this.getAccessibleColor(this.$company.company_options.primary_color.value), "a11yPrimary");
      this.a11yColorSecondary = this.getRGBColor(this.getAccessibleColor(this.$company.company_options.secondary_color.value), "a11ySecondary");

      this.colors = this.primaryColor + "; " + this.secondaryColor + "; " + this.a11yColorPrimary + "; " + this.a11yColorSecondary;
    },
    setCompanyOptions (company) {
      if(company.company_options.length) {
        company.company_options = company.company_options.reduce((prev, el) => {
          let value = null
  
          switch (OptionDataType[el.option_datatype]) {
            case 'integer' :
            case 'float' :
              value = +el.value
              break
            case 'boolean' :
              value = !!(+el.value)
              break
            case 'string' :
              value = el.value
              break
          }
  
          return {
            ...prev, [el.option_code]: {
              dataType: OptionDataType[el.option_datatype],
              value:    value,
            },
          }
        }, {})
      }
    },
     async getLastUnreadNotification() {
      try {
        const latestNotification = await accountService.getLastUnreadNotification(this.$profile.account_uuid, this.$profile.company_id)
        const hasLatestNotification = this.notifications.some(nEvent => nEvent.creation_date == latestNotification.data)
        if (latestNotification.data && !hasLatestNotification) this.getNotifications(true)
      } catch (error) {
        console.error('getLastUnreadNotification ~ error:', error);
      }
    },
    async getNotifications(isLatest = false) {
      await accountService.getNotifications(this.$profile.account_uuid, this.$profile.company_id, this.$profile.language)
        .then((n) => {
          this.notifications = n && n.data
          if (isLatest){
            const mostRecentNotification = this.notifications.filter(n => n.event_type ==  EventType.InternalEvent)[0]
            if (mostRecentNotification) this.$eventBus.$emit('show-alert', { title: mostRecentNotification.description, variant: mostRecentNotification.is_error ? 'danger' : 'success' });
          }
          this.notifications.map((n) => {
            switch (n.event_type) {
              case EventType.ContentCGA:
                n.title = n.title || this.$t('Notification.ContentReleaseTitle', { service: 'Awareness' })
                n.description = n.description || this.$t('Notification.ContentReleaseDesc')
                n.link = '/student/modules'
                break
              case EventType.ContentCGC:
                n.title = n.title || this.$t('Notification.ContentReleaseTitle', { service: 'Channel' })
                n.description = n.description || this.$t('Notification.ContentReleaseDesc')
                n.link = '/student/modules'
                break
              default:
                break
            }
          })
        })
        .catch(() => true)
    },
    markNotificationAsRead(notification, index) {
      accountService.updateNotification(this.$profile.account_uuid, this.$profile.company_id, notification.id, { marked: 1 })
        .then(() => {
          notification.marked = 1;
          Vue.set(this.notifications, index, notification);
        }, (err) => {
          console.log("Notification - error", err);
          this.$eventBus.$emit('show-alert', {
            title: this.$t('Notification.NotificationUpdateError'),
            variant: 'danger'
          })
        });
    },
    logOut(e) {
      if (e?.name === 'NavigationDuplicated')
        return location.reload() && console.error(e) && this.$router.go()

      this.$user = null
      localStorage.clear()
      console.error(e)
      window.location.href = '/logout'
    },
    isActiveItem(path) {
      return path == this.roleRoute ? this.activeItem == this.roleRoute : this.activeItem.startsWith(path);
    },
    setPageTitle() { // i controlli servono a gestire la doppia chiamata nel caso di cambio rotta E cambio ruolo
      const activeItem = this.$refs.navbarRef.$children.filter(e => e.$options.propsData.active === true)[0];
      this.navbarPageTitle = activeItem && activeItem.$el ? activeItem.$el.textContent : '';
      if (activeItem && activeItem.$children.length && activeItem.$children[0].$children.length) {
        this.navbarPageIcon = activeItem.$children[0].$children[0].$options.propsData.name || '';
      }
    },
    async setCurrentRole(role) {
      // get role from the localStorage or the one with higher priority from the user's company
      if (!role) role = this.$company.roles.find(r => r.name === localStorage.getItem('activeRole')) || this.$company.roles[0];

      const oldRole = this.$profile.currentRole;
      const newRole = role.name;
      if (oldRole == newRole) return;

      this.$eventBus.$emit('loading', true);

      Vue.set(this.$profile, 'currentRole', newRole);
      Vue.set(this.$profile, 'currentPermissions', role.permissions || []);
      localStorage.setItem('activeRole', newRole);

      // retrieve companies for the selected role
      let compArr = (await companyService.getCompanies({withCompanyOptions: true})).data?.data || [];

      // update all user companies with company tags and user's tags for each company
      let ucObj = null;
      compArr.forEach(c => {
        const uc = this.$user.companies?.find(uc => uc.company_id == c.company_id)
        c.userTags = uc?.user_tags || {};
        if (c.company_id == this.$company.company_id) ucObj = c;
      });

      Vue.set(this.$profile, 'companies', compArr.sort((a, b) => a.name.localeCompare(b.name)));

      // update current company and profile service permissions
      await this.setCurrentCompany(ucObj || this.$company);

      const roleObj = this.getRoleObj();
      if (oldRole || (this.roleRoute !== roleObj.baseRoute && !oldRole))
        this.$router
          .replace(roleObj.baseRoute || '/')
          .catch(() => {})

      this.$eventBus.$emit('loading', false);
    },
    async setCurrentCompany(company, callback) {
      // retrieve the company from the profile because is updated with the company and user's tags
      company = this.$profile.companies.find(c=>c.company_id == company.company_id) || company;
      this.setCompanyOptions(company);

      // update profile filtered tags for the current company
      let apiOrgFilter = {}
      if (!this.$profile.currentPermissions?.includes('admin-nofilter-orgs') && company.tags?.orgs && company.userTags) {
        for (const org in company.tags.orgs) {
          const orgData = company.tags.orgs[org];
          if (orgData.filter) {
            apiOrgFilter[org] = company.userTags[org] || null;
          }
        }
      }
      this.$profile.filteredTags = Object.keys(apiOrgFilter).length ? apiOrgFilter : null

      // set service licenses to the user
      Object.assign(this.$currentCompany, company)
      await this.updateProfileServicePermissions(company.company_id);
      if (callback) callback();
    },
    setCgaType () {
      return awarenessService.getCgaType()
        .then(cgaTypeRes =>
          Vue.prototype.$cgaType = cgaTypeRes.data.reduce((prev, el) => ({
            ...prev, [el.name.replace(' ', '')]: {
              id:          el.cga_type_id,
              name:        el.name,
              service:     el.service_id,
              description: el.description,
            },
          }), {
            data:         cgaTypeRes.data,
            userCgaTypes: cgaTypeRes.data.filter(el => this.$profile.hasService(el.service_id))
                            .map(el => el.cga_type_id),
            all:          () => Vue.prototype.$cgaType.data.filter(el => el.service_id !== null)
              .map(el => el.cga_type_id),
            service:      (id) => Vue.prototype.$cgaType.data
                .filter(el => id === ServiceType.Remediation ? el.service_id != null : el.service_id === id)
                ?.map(el => Vue.prototype.$cgaType.type(el.cga_type_id)),
            type:         (id) => Vue.prototype.$cgaType.data.filter(el => el.cga_type_id === id)
              ?.map(el => ({
                id:          el.cga_type_id,
                name:        el.name,
                service:     el.service_id,
                description: el.description,
              }))[0],
            labels:       (ids = []) => Vue.prototype.$cgaType.data.filter(el => el.service_id !== null)
              .filter(el => ids.includes(el.cga_type_id))
              .map(el => ({ label: el.name, value: el.cga_type_id })),
          }),
        )
        .catch(e => this.logOut(e))
    },
    async updateProfileServicePermissions(companyId) {
      // retrieve current activeRole
      this.$eventBus.$emit('loading', true)
      const activeRole = localStorage.getItem('activeRole');

      if (!this.$methods.isRole(activeRole, Roles.Student)) {
        // retrieve licenses for the currentCompany and evaluate service permissions based on the peoples that are assigned to the licenses
        const licensesRes = await companyService.getLicenses(companyId || this.$currentCompany.company_id);
        Vue.set(this.$profile, 'licenseByService', licensesRes.data.filter(l => l.active_licenses > 0).map(l => l.service_id));
      } else {
        Vue.set(this.$profile, 'licenseByService', this.$profile.origLicenseByService);
      }
      this.$eventBus.$emit('loading', false)
    },
    getRGBColor(hex, type) {
      let color = hex.replace(/#/g, "");
      // rgb values
      const r = parseInt(color.substr(0, 2), 16);
      const g = parseInt(color.substr(2, 2), 16);
      const b = parseInt(color.substr(4, 2), 16);
      return `--color-${type}: ${r}, ${g}, ${b};`;
    },
    getAccessibleColor(hex) {
      let color = hex.replace(/#/g, "");
      // rgb values
      const r = parseInt(color.substr(0, 2), 16);
      const g = parseInt(color.substr(2, 2), 16);
      const b = parseInt(color.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "#000000" : "#FFFFFF";
    },
    getRoleObj() {
      const role = this.$methods.getBaseRole(this.$profile.currentRole);

      return (this.$profile && this.$roleMap && this.$roleMap[role]) || ''
    }
  }
};
</script>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans antialiased;
  @apply bg-white text-primary-500;
  @apply overflow-x-hidden;
}

.user-sidebar-wrapper {
  @apply absolute top-8 right-8 xl:top-11 xl:right-11 z-[41];
}

.user-sidebar {
  @apply w-full;
  @apply flex flex-col h-full;
}

.user-sidebar-actions {
  @apply flex justify-end grid-cols-1 gap-3 auto-cols-auto grid-flow-col-dense;
  @apply mb-6;
}

.user-sidebar-actions > * {
  @apply w-auto;
}

*::-webkit-scrollbar {
  width: 0 !important;
}
* { scrollbar-width: none;}
* { -ms-overflow-style: none; }

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
.screen-reader-text:focus {
  background-color: black;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  clip: auto !important;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  left: 0.3em;
  padding: 0.8em 1.5em;
  width: auto;
  z-index: 100000;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

@layer base {
  @font-face {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../public/fonts/Epilogue-400.woff2) format('woff2'), url(../public/fonts/Epilogue.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../public/fonts/Epilogue-500.woff2) format('woff2'), url(../public/fonts/Epilogue.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../public/fonts/Epilogue-600.woff2) format('woff2'), url(../public/fonts/Epilogue.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../public/fonts/Epilogue-700.woff2) format('woff2'), url(../public/fonts/Epilogue.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}
</style>
