<template>

    <div>
        <!-- Template Preview Modal -->
        <Modal 
            :id="'template-modal' + templateId" 
            ref="templateModal" 
            :open.sync="templateModal"
            :title="template && template.name" 
            :trigger="trigger"
            footer-alignment="right" 
            full-height
            with-close-button
            with-expand-button
        >   
        <div class="flex flex-col h-full">
            <div v-if="campaignId || isReadOnly" class="flex flex-col h-full grow">
                <FormGroup html-for="languageTemplateSelect" :label="$t('Campaigns.AvailableLanguages')">
                    <Select id="languageTemplateSelect" v-model="previewLanguage" :options="langOptions" @input="reloadPreview"></Select>
                </FormGroup>

                <FormGroup v-if="templateType!=TEMPLATE_TYPE_SMS" html-for="templateSubject" :label="$t('Campaigns.Subject')">
                    <Input id="templateSubject" v-model="emailSubject" :disabled="true"></Input>
                </FormGroup>

                <FormGroup v-if="templateType!=TEMPLATE_TYPE_SMS" html-for="templateSender" :label="$t('Campaigns.Sender')">
                    <Input id="templateSender" v-model="emailSender" type="email" :disabled="true"></Input>
                </FormGroup>

                <FormGroup v-if="templateType==TEMPLATE_TYPE_SMS" html-for="smsSender" :label="$t('Campaigns.Sender')">
                    <Input id="smsSender" v-model="smsSender" :disabled="true"></Input>
                </FormGroup>

                <div class="preview-template grow" :class="{ 'sms-type': templateType == TEMPLATE_TYPE_SMS }">
                    <div v-if="templateType!=TEMPLATE_TYPE_SMS" class="h-full my-2 border">
                        <Editor v-if="showEditor" :code="codePreview" :line-numbers="true" @input="checkBodyPlaceholder"></Editor>
                        <iframe v-if="previewLg && !showEditor" id="templatePreviewEmailIframe" :title="$t('Wcag.Preview')" :srcdoc="previewLg" class="h-full w-full" @load="emailIFrameLoaded"></iframe>
                    </div>

                    <div v-if="templateType == TEMPLATE_TYPE_SMS" class="h-full my-2 border">
                        <div v-if="codePreview && previewLg" class="h-full">
                            <iframe v-show="isLoaded" id="templatePreviewIframe" :title="$t('Wcag.Preview')" :srcdoc="previewLg" class="px-4 py-4 w-full h-full" frameborder="0" @load="smsIFrameLoaded"></iframe>
                        </div>
                        <div v-else class="editor-container">
                            <Txt small class="italic">{{ $t('Template.InfoCreateContentEditor') }}</Txt>
                        </div>
                    </div>
                </div>

                <div class="flex justify-end pt-5">
                    <Button v-if="!isReadOnly" ref="testTemplateBtn" variant="outline" class="ml-2 mb-3" @click="targetEmailModal">
                        {{ $t('Campaigns.TestTemplate') }}
                    </Button>
                </div>
            </div>

            <Tabs v-if="!campaignId" :tab-nav="tabs" class="flex flex-col grow overflow-hidden" @select="landingPageHTML=null">
                <template #tabContent-1>
                    <div class="flex flex-col tab-content gap-y-0 pt-5 h-full">
                        <FormGroup html-for="languageTemplateSelect" :label="$t('Campaigns.AvailableLanguages')">
                            <Select id="languageTemplateSelect" v-model="previewLanguage" :options="langOptions" @input="reloadPreview"></Select>
                        </FormGroup>

                        <FormGroup v-if="templateType!=TEMPLATE_TYPE_SMS" html-for="templateSubject" :label="$t('Campaigns.Subject')">
                            <Input id="templateSubject" v-model="emailSubject" :disabled="!canEditTemplate" @change="checkSubjectPlaceholder" @input="updateTemplateMetadata"></Input>
                        </FormGroup>

                        <FormGroup v-if="templateType!=TEMPLATE_TYPE_SMS" html-for="templateSender" :label="$t('Campaigns.Sender')">
                            <Input id="templateSender" v-model="emailSender" :disabled="!canEditTemplate" type="email" @input="updateTemplateMetadata"></Input>
                        </FormGroup>

                        <FormGroup v-if="templateType==TEMPLATE_TYPE_SMS" html-for="smsSender" :label="$t('Campaigns.Sender')">
                            <Input id="smsSender" v-model="smsSender" :disabled="!canEditTemplate"
                                :errored="isUnvalidSender" @change="checkSMSSender" @input="updateTemplateMetadata"></Input>
                        </FormGroup>

                        <div class="grid gap-x-10 grid-cols-1 mt-2">
                            <div v-if="showEditor && canEditTemplate">
                                <FormGroup :label="$t('Template.SelectTagToCopy')" class="pt-4">
                                    <div class="flex flex-row gap-x-2" >
                                        <FormGroup class="!grow" html-for="tagTemplateSelect" no-margin sr-only :label="$t('Template.SelectTagToCopy')">
                                            <Select id="tagTemplateSelect" v-model="selectedTag" :options="tagOptions"></Select>
                                        </FormGroup>
                                        <Button :disabled="!selectedTag" variant="outline" @click="copyTemplateTag">
                                            {{ $t('General.Copy') }}
                                        </Button>
                                        <Button ref="uploadImgBtn" variant="outline" @click="uploadImageModal = true">
                                            <template #prefix>
                                                <Icon name="image" />
                                            </template>
                                            {{ $t('Template.UploadImgLabel') }}
                                        </Button>
                                    </div>
                                </FormGroup>
                            </div>

                            <div class="flex justify-end" :class="{ 'mt-2': showEditor }">
                                <Button v-if="templateType!=TEMPLATE_TYPE_SMS" variant="outline" class="mb-3" :disabled="!canEditTemplate" @click="showEditor = !showEditor">
                                    <template #prefix>
                                        <Icon v-if="showEditor" name="eye" />
                                        <Icon v-else name="edit" />
                                    </template>
                                    {{ showEditor ? $t('Template.ShowPreview') : $t('Template.ShowEditor') }}
                                </Button>
                                <Button v-if="templateType!=TEMPLATE_TYPE_SMS" variant="outline" class="ml-2 mb-3" :disabled="disableSaveBodyButton" @click="saveTemplate">
                                    {{ $t('General.Save') }}
                                </Button>
                            </div>
                        </div>

                        <!-- Template Preview/Editor -->
                        <div class="preview-template" :class="{ 'sms-type': templateType == TEMPLATE_TYPE_SMS }">
                            <div v-if="templateType!=TEMPLATE_TYPE_SMS" class="h-[300px] my-2 border">
                                <Editor v-if="showEditor" ref="templateEditorRef" :code="codePreview" :line-numbers="true" @input="checkBodyPlaceholder"></Editor>
                                <iframe v-if="previewLg && !showEditor" id="templatePreviewEmailIframe" :title="$t('Wcag.Preview')" :srcdoc="previewLg" class="h-full w-full" @load="emailIFrameLoaded"></iframe>
                            </div>

                            <div v-if="templateType == TEMPLATE_TYPE_SMS">
                                
                                <Txt class="block text-sm text-primary-300 mb-1">{{$t('Template.SMSTemplateCode')}}</Txt>
                                <div class="flex flex-row gap-x-2">
                                    <FormGroup class="!grow" html-for="tagOptionSelect" sr-only :label="$t('Template.SMSTemplateCode')">
                                        <Select id="tagOptionSelect" v-model="selectedTag" :options="tagOptions"></Select>
                                    </FormGroup>

                                    <Button :disabled="!selectedTag" variant="outline" class="mr-6" @click="copyTemplateTag">
                                        {{ $t('General.Copy') }}
                                    </Button>

                                    <Button variant="outline" :disabled="disableSaveSMSBodyButton" @click="saveTemplate">
                                        {{ $t('General.Save') }}
                                    </Button>
                                </div>
                                
                                <textarea v-model="codePreview" rows="6" class="border block w-full whitespace-pre-wrap my-4 p-2" @input="checkBodyPlaceholder($event.target.value)"></textarea>
                                
                                <Txt extraSmall class="italic mb-4">{{ $t('Template.SMSTemplateHint') }}</Txt>
                                
                                <Txt class="block text-sm text-primary-300 mb-1">{{$t('Template.Preview')}}</Txt>
                            
                                <div v-if="codePreview && previewLg">
                                    <iframe v-show="isLoaded" id="templatePreviewIframe" :title="$t('Wcag.Preview')" :srcdoc="previewLg" class="px-4 w-full min-h-[200px] border" frameborder="0" @load="smsIFrameLoaded"></iframe>
                                </div>
                                <div v-else class="editor-container">
                                    <Txt small class="italic">{{ $t('Template.InfoCreateContentEditor') }}</Txt>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template #tabContent-2>
                    <div class="flex flex-col tab-content gap-y-0 pt-5 h-full">
                        <FormGroup html-for="templateName" :label="$t('Template.Name')">
                            <Input id="templateName" v-model="currentTemplate.name" :disabled="!canEditTemplate" ></Input>
                        </FormGroup>

                        <FormGroup html-for="landingPageId" :label="$t('Template.LandingPageId')">
                            <Select id="landingPageId" v-model="currentTemplate.landing_page_id" :options="landingpageIdOptions" :disabled="!canEditTemplate"></Select>
                        </FormGroup>

                        <FormGroup html-for="landingPage" :label="$t('Template.LandingPage')">
                            <Input id="landingPage" v-model="currentTemplate.landing_page_url" :disabled="!canEditTemplate" ></Input>
                        </FormGroup>

                        <FormGroup html-for="tags" :label="$t('Template.Tags')">
                            <textarea id="tags" v-model="currentTemplate.tags" :disabled="!canEditTemplate" rows="4" class="border block w-full whitespace-pre-wrap"></textarea>
                        </FormGroup>

                        <FormGroup html-for="difficulty" :label="$t('Template.Difficulty')">
                            <Input id="difficulty" v-model="currentTemplate.difficulty" :disabled="!canEditTemplate" type="number" :min="0" :max="1" :step="0.1" ></Input>
                        </FormGroup>

                        <FormGroup html-for="templateType" :label="$t('Template.Type')">
                            <div class="flex gap-4">
                                <Select id="templateType" v-model="currentTemplate.type" disabled :options="typeOptions"></Select>
                            </div>
                        </FormGroup>
                        <FormGroup v-if="currentTemplate.type != TEMPLATE_TYPE_SMS" html-for="attachment" :label="$t('Template.HasAttachment')">
                            <div class="flex gap-4">
                                <Toggle id="attachment" v-model="currentTemplate.has_attachment" :disabled="!canEditTemplate"></Toggle>
                            </div>
                        </FormGroup>

                        <FormGroup v-if="currentTemplate.type!==TEMPLATE_TYPE_SMS" html-for="attachmentId" :label="$t('Template.AttachmentId')">
                            <div class="flex gap-4">
                                <Select id="attachmentId" v-model="currentTemplate.attachment_id" :disabled="!currentTemplate.has_attachment" :options="attachmentOptions"></Select>
                            </div>
                        </FormGroup>

                        <FormGroup html-for="deleted" :label="$t('Template.IsDeleted')">
                            <Toggle id="deleted" v-model="currentTemplate.deleted" :checked-value="null" :unchecked-value="1" :disabled="!canEditTemplate">{{ $t('Template.IsDeleted') }}</Toggle>
                        </FormGroup>

                        <FormGroup html-for="forced" :label="$t('Template.IsForced')">
                            <Toggle id="forced" v-model="currentTemplate.forced" :checked-value="1" :unchecked-value="null" :disabled="!canEditTemplate || currentTemplate.deleted == 1">{{ $t('Template.IsForced') }}</Toggle>
                        </FormGroup>

                        <div class="flex justify-end">
                            <Button variant="outline" :disabled="disableSaveInfoButton" @click="updateTemplateBasicInfo">
                                {{ $t('General.Save') }}
                            </Button>
                        </div>
                    </div>
                </template>

                <template #tabContent-3>
                    <div class="flex flex-col tab-content gap-y-2 pt-2 h-full">
                        <Table :name="$t('Template.Langs')" alternate-color="white" class="!overflow-y-auto min-h-[10rem]">
                            <template #head>
                                <TableCell head>{{ $t('Template.Language') }}</TableCell>
                                <TableCell v-if="canEditTemplate" head right>{{ $t('General.Actions') }}</TableCell>
                            </template>
                            <TableRow v-for="lang in langOptions" :key="'lang'+lang.value" :record="lang">
                                <TableCell><Txt>{{ lang.label }}</Txt></TableCell>
                                <TableCell v-if="canEditTemplate" right>
                                    <Button :ref="'confirmDeleteBtn_'+ lang.value" variant="ghost" small color="light" class="text-primary-300 hover:text-violet-700" @click="confirmDeleteTemplateLanguage(lang.value)">
                                        <Icon name="trash" alt="$t('General.Delete')" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </Table>

                        <hr />

                        <Txt weight="bold" small>{{$t('Template.AddContent')}}</Txt>
                    
                        <Txt small class="text-gray-700 mb-5">{{ $t('Template.NewTemplateLanguage') }}</Txt>
                        <FormGroup html-for="languageNewTemplateSelect" :label="$t('Campaigns.AvailableLanguages')">
                            <Select id="languageNewTemplateSelect" v-model="newTemplateLanguage" :disabled="!canEditTemplate" :options="isoLangOptions" erasable></Select>
                        </FormGroup>

                        <div class="flex justify-end">
                            <Button variant="outline" :disabled="!canEditTemplate || !newTemplateLanguage" @click="addTemplateLanguage">
                                 {{ $t('Template.AddTemplateContent') }}
                            </Button>
                        </div>

                        <hr />

                        <Txt weight="bold" small>{{$t('Template.AddCountry')}}</Txt>

                        <Txt small class="text-gray-700 mb-5">{{ $t('Template.NewTemplateCountry') }}</Txt>
                        <FormGroup html-for="inputTagsLanguage" :label="$tc('General.Country', 2)">
                            <InputTags id="inputTagsLanguage" v-model="parseCountries" :disabled="!canEditTemplate" :options="isoCountryOptions" :placeholder="tagInputPlaceholder" @input="addTag" @remove="removeTag"></InputTags>
                        </FormGroup>

                        <div class="flex justify-end">
                            <Button variant="outline" :disabled="!canEditTemplate || disableSaveCountryButton" @click="updateTemplateBasicInfo">
                                 {{ $t('General.Save') }}
                            </Button>
                        </div>
                    </div>
                </template>

                <template #tabContent-4>
                    <div v-if="companyData.cgsmtp" class="flex flex-col tab-content gap-y-2 pt-5 h-full">
                        <Txt weight="bold" small>{{$t('Template.DownloadTemplateXML')}}</Txt>

                        <div class="grid gap-x-10 grid-cols-1 sm:grid-cols-2 mt-2">
                            <Txt small class="text-gray-700 mb-5">{{ $t('Template.ExportXMLLabel') }}</Txt>
                            <div class="flex justify-end">
                                <Button variant="outline" @click="exportTemplate">
                                    {{ $t('Template.ExportXML') }}
                                </Button>
                            </div>
                        </div>

                        <hr />

                        <Txt weight="bold" small>{{$t('Template.SingleFeatureCheck')}}</Txt>

                        <div class="grid gap-x-10 grid-cols-1 sm:grid-cols-2 mt-2">
                            <Txt small class="text-gray-700 mb-5">{{ $t('Template.AvailableLanguagesFeatures') }}</Txt>
                            <FormGroup html-for="languageExportSelect" :label="$t('Template.AvailableLanguagesFeatures')" sr-only>
                                <Select id="languageExportSelect" v-model="previewLanguage" :options="langOptions" @input="reloadPreview"></Select>
                            </FormGroup>
                        </div>

                        <div class="grid gap-x-10 grid-cols-1 sm:grid-cols-2 mt-2">
                            <Txt small class="text-gray-700 mb-5">{{ $t('Template.ExportHTMLLabel') }}</Txt>
                            <Button @click="downloadTemplateHTML">
                                {{ $t('Template.ExportHTML') }} ({{ previewLanguage.toUpperCase() }})
                            </Button>
                        </div>

                        <div v-if="isAdmin" class="grid gap-x-10 grid-cols-1 sm:grid-cols-2 mt-2">
                            <Txt small class="text-gray-700 mb-5">{{ $t('Template.TestTemplateSelLangLabel') }}</Txt>
                            <Button ref="testTemplateLangBtn" :disabled="!canTestTemplate" @click="targetEmailModal(true)">
                                {{ $t('Campaigns.TestTemplate') }} ({{ previewLanguage.toUpperCase() }})
                            </Button>
                        </div>

                        <div class="grid gap-x-10 grid-cols-1 sm:grid-cols-2 mt-2">
                            <Txt small class="text-gray-700 mb-5">{{ $t('Template.TestTemplateLabel') }}</Txt>
                            <Button ref="testTemplateBtn" :disabled="!canTestTemplate" @click="targetEmailModal(false)">
                                {{ $t('Campaigns.TestTemplate') }}
                            </Button>
                        </div>

                        <hr />

                        <div v-if="companyOptions && companyOptions.length" class="space-y-2">
                            <Txt weight="bold" small>{{$t('Template.CopyTemplate')}}</Txt>

                            <FormGroup html-for="copyTemplateCompanySelect" :label="$t('Template.CopyTemplate')" sr-only>
                                <Select id="copyTemplateCompanySelect" v-model="copyTemplateCompanyId" :options="companyOptions"></Select>
                            </FormGroup>
    
                            <div class="flex justify-end">
                                <Button @click="copyTemplate">
                                    {{ $t('General.Copy') }}
                                </Button>
                            </div>
                        </div>

                    </div>
                </template>

                <template #tabContent-5>
                    <div class="flex flex-col tab-content gap-y-4 pt-5 h-full">
                        <FormGroup html-for="languageHintSelectEdit" :label="$t('Campaigns.AvailableLanguages')">
                            <Select id="languageHintSelectEdit" v-model="previewLanguage" :options="langOptions" @input="loadHints(); reloadPreview()"></Select>
                        </FormGroup>
                        
                        <ModalForm ref="newHintModal" :title="$t(hintData.title)" :state="newHintModal" :data="hintData" :trigger="$refs.newHintBtn" @update="updateHint"/>
                        
                        <Table :name="$t('Template.Hints')" class="my-8" alternate-color="white">
                            <template #head>
                                <TableCell head>{{ $t('Template.HintID') }}</TableCell>
                                <TableCell head>{{ $t('Template.HintClass') }}</TableCell>
                                <TableCell head>{{ $t('Template.HintPriority') }}</TableCell>
                                <TableCell head>{{ $t('Template.HintBody') }}</TableCell>
                                <TableCell v-if="canEditTemplate" head right>{{ $t('General.Actions') }}</TableCell>
                            </template>
                            <TableRow v-for="hint in hintsList" :key="'hint'+hint.hint_id" :record="hint">
                                <TableCell><Txt>{{ hint.hint_id }}</Txt></TableCell>
                                <TableCell><Txt>{{ hint.class }}</Txt></TableCell>
                                <TableCell><Txt>{{ hint.priority }}</Txt></TableCell>
                                <TableCell class="max-w-sm truncate"><Txt>{{ hint.body }}</Txt></TableCell>
                                <TableCell v-if="canEditTemplate" right>
                                    <Button variant="ghost" small color="light" class="text-primary-300 hover:text-violet-700"  aria-label="edit" :disabled="!canEditTemplate" @click="editHint(hint)">
                                        <Icon name="edit" alt="$t('General.Edit')" />
                                    </Button>  
                                    <Button variant="ghost" small color="light" class="text-primary-300 hover:text-violet-700" :disabled="!canDeleteTemplate" @click="confirmDeleteHint(hint)">
                                        <Icon name="trash" alt="$t('General.Delete')" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </Table>

                        <!-- Pagination widget -->
                        <!--<Pagination
                            class="mt-5"
                            :page="hintTable.currentPage"
                            :items-per-page="hintTable.perPage"
                            :total-items="hintTable.totalRows"
                        />-->

                        <div class="flex justify-end">
                            <Button ref="newHintBtn" class="btn-action float-right mb-3" :disabled="!canEditTemplate" @click="showModalCreateHint">
                                {{ $t('Template.NewHint') }}
                            </Button>
                        </div>
                    </div>
                </template>
            </Tabs>
        </div>
        </Modal>

        <!-- Confirm modal -->
        <Modal :open.sync="confirmModal" :title="confirmModalTitle" :trigger="confirmDeleteRef" :description="confirmModalDescription" footer-alignment="center" small with-close-button>
            <template #footer>
                <Button variant="outline" @click="confirmModal = false">{{ $t('General.Cancel') }}</Button>
                <Button @click="confirmModal = false; confirmModalCallback()">{{ $t('General.Confirm') }}</Button>
            </template>
        </Modal>
        
        <!-- Test Template Attack Modal -->
        <Modal
            :id="'test-template-attack-' + templateName" 
            :open.sync="testTemplateAttackModal"
            :title="$t('Campaigns.TestTemplate')"
            :trigger="testTemplateTrigger"
            :description="$t('Campaigns.TestAttackMessage')"
            footer-alignment="center"
            small
            with-close-button
        >
            <Input v-model="targetName" class="mb-4" :placeholder="$t('General.Name')"></Input>
            <Input v-if="templateType==TEMPLATE_TYPE_SMS" v-model="targetNumber" :placeholder="$t('General.Number')"
                :errored="targetNumber && !Number(targetNumber)" :error-message="invalidNumberError"
            ></Input>
            <Input v-else v-model="targetEmail" :placeholder="$t('General.Email')"
                :errored="!isValidDomain" :error-message="invalidDomainError"
            ></Input>

            <template #footer>
                <Button @click="testTemplateAttackModal = false">{{ $t('General.Cancel') }}</Button>
                <Button variant="outline" :disabled="(templateType==TEMPLATE_TYPE_EMAIL && !targetEmail || !isValidDomain) || (templateType==TEMPLATE_TYPE_SMS && (!targetNumber || !Number(targetNumber)))" @click="testTemplateAttackModal = false; testAttack()">{{ $t('General.Ok') }}</Button>
            </template>
        </Modal>

        <!-- Modal to upload new/copy already uploaded image  -->
        <Modal
            id="uploadImage" 
            :open.sync="uploadImageModal"
            :title="$t('Template.UploadImgLabel')"
            :trigger="$refs.uploadImgBtn"
            footer-alignment="right"
            with-close-button
            full-height
        >
            <Table :name="$t('Template.Images')" selectable :single-selection="true" alternate-color="white" :selected-records.sync="selImage" class="mb-4">
                <template #head>
                    <TableCell head></TableCell>
                    <TableCell head>{{ $t('General.Name') }}</TableCell>
                    <TableCell head right>{{ $t('General.Size') }}</TableCell>
                </template>
                <TableRow v-for="(entry, j) in repo" :key="'tvImg'+j" :record="entry">
                    <TableCell><img :src="entry.icon" class="h-[30px] w-[30px] max-h-[30px] max-w-[30px]"/></TableCell>
                    <TableCell><span :id="entry.name">{{ entry.name }}</span></TableCell>
                    <TableCell right>{{ entry.size }}</TableCell>
                </TableRow>
            </Table>

            <div class="sticky bottom-0">
                <Input v-model="newImg.name" :placeholder="$t('Template.UploadImg')" @input="validate($event, item)" @click="$refs.imgFile.click()">
                    <template #suffix>
                    <Button ref="uploadImgConfirmBtn" @click="$refs.imgFile.click()">
                        {{ $t('Template.UploadImgLabel') }}
                    </Button>
                    </template>
                </Input>
                
                <input
                    ref="imgFile"
                    type="file"
                    hidden
                    accept="image/*"
                    @change="checkDuplicates"
                    @click="$refs.imgFile.value = ''"
                />
            </div>
            
            <template #footer>
                <Button variant="outline" @click="uploadImageModal=false; selectedTag=`{{.Img=`+selImage[0].name+`}}`; copyTemplateTag()">{{ $t('General.Copy') }}</Button>
                <Button @click="uploadImageModal=false">{{ $t('General.Cancel') }}</Button>
            </template>
        </Modal>

        <!-- Modal to prevent replacing images with the same name by error -->
        <Modal
            id="irreversibleOverrideImage" 
            :open.sync="irreversibleOverrideImageModal"
            :trigger="$refs.uploadImgConfirmBtn"
            with-close-button
        >
            <div class="text-center mb-4">
                <Txt class="h3">{{ $t('Template.ConfirmOverrideImageWarning') }}</Txt>
                <p style="font-bold">{{ $t("Template.ConfirmOverrideImage") }}</p>
            </div>
            <div class="flex justify-end">
                <Button variant="outline" class="mr-3" @click="irreversibleOverrideImageModal = false; uploadImg()">{{ $t('General.Override') }}</Button>
                <Button @click="irreversibleOverrideImageModal = false">{{ $t('General.Cancel') }}</Button>
            </div>
        </Modal>

        <!-- Modal for unsaved info -->
        <Modal
            id="unsavedTemplateInfo" 
            :open.sync="unsavedTemplateInfoModal"
            :title="$t('General.UnsavedInfo')"
            :trigger="!templateModal ? trigger : $refs.templateModal"
            :description="$t('General.UnsavedInfoText')"
            small
            footer-alignment="center" 
            with-close-button
        >	
            <template #footer>
                <Button @click="unsavedTemplateInfoModal = false">{{ $t('General.Cancel') }}</Button>
                <Button variant="outline" @click="unsavedTemplateInfoModal=false;templateModal=false;doNotSave=true;">{{ $t('General.Ok') }}</Button>
            </template>
        </Modal>
    </div>
    
</template>

<script>
import { TemplateType, TemplateScope, LandingPageScope, QRCode, Roles } from '@/common/constants';
import templateService from '@/services/template.service';
import landingpageService from '@/services/landingpage.service';
import phishingService from '@/services/phishing.service';
import campaignService from '@/services/campaign.service';
import sanitizeHtml from 'sanitize-html';
import Vue from 'vue';

export default {
    
    data: function() {
        return {
            template: null,
            previewLg: null,
            codePreview: null,
            isLoaded: true,
            showEditor: false,
            alertVariant: 'danger',
            alertMessage: '',
            alertParams: {},
            previewLanguage: null,
            langOptions: [],
            tagOptions: [],
            landingpageIdOptions: [],
            copiedLabel: false,
            selectedTag: null,
            supportMessage: '',
            targetNumber: null,
            targetEmail: this.$profile && this.$profile.email,
            targetName: this.$profile && this.$profile.username,
            templateContent: null,
            currentTemplateContent: null,
            templateContentType: this.TEMPLATE_TYPE_EMAIL,
            newTemplateLanguage: null,
            companyOptions: [],
            attachmentOptions: [],
            copyTemplateCompanyId: null,
            companyList: [],
            currentTemplate: {
                name: null,
                tags: null,
                type: null,
                has_attachment: null,
                attachment_id: null,
                scope: null,
                page_id: null,
                landing_page_url: null,
                landing_page_id: null,
                difficulty: null,
                company_id: null,
                deleted: null,
                forced: null,
                country: null
            },
            newImg: {},
            selImage: [],
            imageRepoSortBy: "name",
            imageRepoSortDesc: false,
            attrList: [],
            smsSender: '',
            emailSender: '',
            emailSubject: '',
            landingPageURL: null,
            landingPageId: null,
            templateHints: [],
            hintsList: [],
            hintTable: {
                perPage: 10,
                currentPage: 1,
                sortBy: 'priority',
                sortDesc: false,
                totalRows: 0,
                filter: '',
                fields: [
                    { key: "hint_id", label: this.$i18n.t('Template.HintID'), sortable: true },
                    { key: "class", label: this.$i18n.t('Template.HintClass'), sortable: true },
                    { key: "priority", label: this.$i18n.t('Template.HintPriority'), sortable: true },
                    { key: "body", label: this.$i18n.t('Template.HintBody'), sortable: true },
                    { key: "actions", label: this.$i18n.t('General.Actions'), sortable: false },
                ]
            },
            hintData: {
                name: "hint-modal",
                item: {},
                submitHandler: "updateHint",
                fields:[
                    {   id: "hint_id",
                        label: 'Template.HintID',
                        type: "text",
                        display: false,
                        required: false,
                        disabled: true,
                        placeholder: 'Template.HintID'
                    },
                    {   id: "class",
                        label: 'Template.HintClass',
                        type: "select",
                        display: true,
                        options: [],
                        required: true,
                        placeholder: 'Template.HintClass'
                    },
                    {   id: "priority",
                        label: 'Template.HintPriority',
                        type: "number",
                        display: true,
                        max: 2147483647,
                        placeholder: 'Template.HintPriority'
                    },
                    {   id: "language",
                        label: 'Template.Language',
                        type: "select",
                        required: true,
                        options: [],
                        display: true,
                        placeholder: 'Template.Language'
                    },
                    {   id: "icon",
                        label: 'Template.HintIcon',
                        type: "select",
                        required: false,
                        options: [],
                        display: true,
                        placeholder: 'Template.HintIcon'
                    },
                    {   id: "body",
                        label: 'Template.HintBody',
                        type: "textarea",
                        required: true,
                        display: true,
                        rows: 8,
                        lineheight: 4,
                        placeholder: 'Template.HintBody'
                    }
                ]
            },
            hintToDelete: null,
            isoCountryOptions: null,
            isoLanguages: null,
            isoLangOptions: null,
            parseCountries: null,
            tagInputPlaceholder: this.$t('Template.AvailableCountryPlaceholder'),
            selectOptionString: this.$i18n.t('Template.AllLanguages'),
            templateLangToDelete: null,
            tabs: [],
            doNotSave: false,

            // modals
            testTemplateAttackModal: false,
            testTemplateTrigger: null,
            uploadImageModal: false,
            unsavedTemplateInfoModal: false,
            irreversibleOverrideImageModal: false,
            confirmModal: false,
            confirmModalTitle: null,
            confirmModalDescription: null,
            confirmModalCallback: null,
            templateModal: false,
            newHintModal: false,
            emailDomains: null,
            validDomain: { count: 0, domain: ''},
            isAdmin: this.$checkPermission(['phishing-view-all-template']),
            canViewGlobalTemplate: this.$checkPermission(['phishing-view-all-template', 'phishing-view-global-template']),
            canViewGlobalLandingPages: this.$checkPermission(['phishing-view-all-landingpage', 'phishing-view-global-landingpage']),
            canEditTemplate: false,
            canDeleteTemplate: this.$checkPermission(['phishing-delete-all-template', 'phishing-delete-template']),
            canTestTemplate: this.$checkPermission(['phishing-test-all-template', 'phishing-test-template']),
            typeOptions: [],
            confirmDeleteRef: null,
        };
    },
    props: ["search-text", "company-data", "campaign-id", "repo", "is-read-only", "trigger"],
    computed: {
        templateId() {
            return this.template && this.template.template_id;
        },
        templateCompanyId() {
            return this.template && this.template.company_id;
        },
        templateName() {
            return this.template && this.template.name;
        },
        templateType() {
            return this.template && this.template.type;
        },
        disableSaveInfoButton() {
            if (!this.canEditTemplate) return true;
            
            let status = true;

            if(!this.currentTemplate || !this.template) {
                status = true;
            }
            this.attrList.forEach(name => {
                if(this.currentTemplate[name] !== this.template[name]) {
                    status = false;
                }
            });
            return status;
        },
        disableSaveBodyButton() {
            if(!this.templateContent || !this.currentTemplateContent || !this.emailSubject || !this.emailSender) {
                return true;
            }

            let savedData = this.templateContent.find(template => { return template.language == this.previewLanguage });
            let previousData = this.currentTemplateContent.find(template => { return template.language == this.previewLanguage });
    
            return JSON.stringify(previousData) === JSON.stringify(savedData);
        },
        disableSaveSMSBodyButton() {
            if(!this.templateContent || !this.currentTemplateContent) {
                return true;
            }

            if(this.smsSender && this.smsSender.length > 11) {
                // SMS sender field must contain 11 characters at most
                return true;
            }
            let savedData = this.templateContent.find(template => { return template.language == this.previewLanguage });
            let previousData = this.currentTemplateContent.find(template => { return template.language == this.previewLanguage });

            return JSON.stringify(previousData) === JSON.stringify(savedData);
        },
        disableSaveCountryButton() {
            let origin = this.currentTemplate && this.currentTemplate.country;
            let current = this.parseCountries && this.parseCountries.map(c => c.value).join('#') || null;
            return (!current && !origin) || current == origin;
        },
        noLanguageAvailable() {
            return this.langOptions && this.langOptions.length == 0;
        },
        isOnlyLanguage() {
            return this.langOptions.length == 1;
        },
        isValidDomain() {
            if (!this.targetEmail) return;
            let trimmeredEmail = this.targetEmail.trim();
            return this.$checkPermission('admin-view-all-company') ? true : ((trimmeredEmail && this.emailDomains && this.emailDomains.length > 0) ? this.emailDomains.includes(trimmeredEmail.substring(trimmeredEmail.indexOf('@'))) : true);
        },
        invalidDomainError(){
            return !this.isValidDomain ? this.$tc('Targets.InvalidEmailDomain', this.validDomain.count, {'domain': this.validDomain.domain}): ''
        },
        invalidNumberError(){
            return this.targetNumber && !Number(this.targetNumber) ? this.$t('General.InvalidNumber'): '';
        },
        isUnvalidSender() {
            if(!this.smsSender) return;

            var senderRegex = new RegExp(/[a-zA-Z0-9 .]{0,11}/);
            var sender = senderRegex.exec(this.smsSender);

            return this.smsSender.length > 11 || sender[0] !== this.smsSender;
        }
    },
    watch: {
        templateModal(status) {
            if (!status && !this.doNotSave) {
                this.manageUnsavedInfo()
            }
        }
    },
    methods: {
        async setLandingPageOptions() {
            if (this.campaignId || this.isReadOnly) return;
            const lands = await landingpageService.getLandingPages(this.companyData.company.company_id, this.canViewGlobalLandingPages? LandingPageScope.GlobalAndCompany : LandingPageScope.Company);

            // empty the array of landing pages options
            this.landingpageIdOptions = [];

            this.landingpageIdOptions.push({ value: null, label: this.$t('Company.DefaultLandingPagePlaceholder') });

            let orderedLands = lands.data.sort((a, b) => { return (a.scope >= b.scope) || (a.page_id > b.page_id); });
            
            orderedLands.forEach(temp => {
                let lpname = (temp.scope == LandingPageScope.Global)? this.$t('Template.LandingPageNameGlobal', { id: temp.page_id, name: temp.name }): this.$t('Template.LandingPageName', { id: temp.page_id, name: temp.name });
                this.landingpageIdOptions.push( { value: temp.page_id, label: lpname } );
            });            
        },
        async showPreviewModal(template) {
            this.template = template;
            
            this.getTemplateContent(template);
            this.showEditor = false;
            this.selectedTag = null;

            Vue.set(this.tagOptions.find(el => el.value === '{{.CompanyLogoAsDataURL}}'), 'disabled', template && template.type == this.TEMPLATE_TYPE_SMS);

            let tagQRCodeIndex = this.tagOptions.findIndex(el => el.value === '{{.QRCode}}');

            // QRCode tag MUST be hidden for SMS Templates and for non admin users whose Company hasn't enabled QRCode remediations
            if ((template && template.type != this.TEMPLATE_TYPE_SMS) && (this.$profile.hasRoles(Roles.Admin) || this.companyData.enable_rem_qrcode)) {
                if(tagQRCodeIndex == -1) {
                    this.tagOptions.push({ text: this.$i18n.t('General.QRCode'), value: '{{.QRCode}}', disabled: false });
                }
            } else {
                if(tagQRCodeIndex != -1) {
                    this.tagOptions.splice(tagQRCodeIndex, 1);
                }
            }

            if(template && template.type == this.TEMPLATE_TYPE_SMS) {
                let previewBody = template.body.find(t => { return t.language == this.previewLanguage });

                var senderRegex = new RegExp(/<cgsender>(.*)<\/cgsender>/);
                var sender = previewBody ? senderRegex.exec(previewBody.body) : null;

                this.smsSender = sender ? sender[1] : '';
            }

            this.parseCountryList(template);
            
            this.attrList.forEach(name => {
                console.debug(name + ': ', 'current => ', this.currentTemplate[name], 'template => ', template[name]);
                this.currentTemplate[name] = template[name];
            });

            this.supportMessage = this.companyData && this.companyData.contact? this.$t('Campaigns.ContactUsTemplate', { param: this.companyData.contact }) :
                this.$t('Campaigns.ContactSupportTemplate');

            this.companyOptions = [];

            if(this.$checkPermission('phishing-edit-all-template')){
                this.companyOptions.unshift( { value: null, label: this.$t('Template.Global') } );
            }

            this.companyList.forEach(c => {
                this.companyOptions.push( { value: c.company_id, label: c.name } );
            });
            
            this.copyTemplateCompanyId = this.companyOptions.length? this.companyOptions[0].value : null;

            this.setLandingPageOptions();
            
            if(!this.campaignId) {
                let scope = this.canViewGlobalTemplate? TemplateScope.GlobalAndCompany : TemplateScope.Company;

                let attachmentList = await templateService.getTemplateAttachments(template.company_id, scope);

                // Group Attachments by ID to select the proper preview language
                const groupedById = attachmentList.data.list.reduce((acc, obj) => {
                    const key = obj.id;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});

                // Create the result array with the preferred language condition
                this.attachmentOptions = Object.keys(groupedById).map(key => {
                    const items = groupedById[key];
                    let preferredItem = items.find(item => item.language.toLowerCase() == this.$i18n.locale);
                    if(!preferredItem) preferredItem = items.find(item => item.language.toLowerCase() == "en") || items[0];
                    
                    let label = (preferredItem.scope == TemplateScope.Global)? this.$t('Template.LandingPageNameGlobal', { id: preferredItem.id, name: preferredItem.description }) : 
                        this.$t('Template.LandingPageName', { id: preferredItem.id, name: preferredItem.description });
                    return { text: label, value: preferredItem.id };
                });

                this.attachmentOptions.unshift({ value: null, text: this.$t('Attachment.DefaultPlaceholder') });
            }

            // define tabs
            let isNoLangAvailable = this.noLanguageAvailable;
            this.tabs = [
                { name: this.$t('Template.Preview'), isActive: !isNoLangAvailable, disabled: isNoLangAvailable },
                { name: this.$t('Template.BasicInfo'), isActive: isNoLangAvailable, disabled: false},
                { name: this.$t('Template.Langs'), isActive: false, disabled: false},
                { name: this.$t('Template.Features'), isActive: false, disabled: isNoLangAvailable},
                { name: this.$t('Template.Hints'), isActive: false, disabled: isNoLangAvailable}
            ];

            // if the current template is global (companyId is null) can be edited only by users with phishing-edit-all-template permission
            this.canEditTemplate = (!this.templateCompanyId)? this.$checkPermission('phishing-edit-all-template') : this.$checkPermission(['phishing-edit-all-template', 'phishing-edit-template'])
            
            this.doNotSave = false;
            this.templateModal = true;
        },
        parseCountryList(template) {
            // Parse template country string to retrieve country iso code
            let tempCountries = template && template.country && template.country.split('#');
            // Retrieve country object from iso code, or initialize the "empty/all countries" object
            tempCountries = tempCountries ? tempCountries.map((i) => this.isoCountryOptions.filter(p => p.value == i)[0]): [{ 'value': null, 'label': this.$t('Template.AllCountries')}];
            // Clean country array in case of mispelled iso code
            tempCountries = tempCountries.filter(c => c);
            this.parseCountries = tempCountries;
        },
        addTag(tags) {
            this.parseCountries = tags.filter((item) => item.value != null);
            this.manageTagsPlaceholder();
        },
        removeTag(tags) {
            this.parseCountries = !tags.length ? [{ 'value': null, 'label': this.$t('Template.AllCountries')}] : tags.filter((item) => item.value != null);
            this.manageTagsPlaceholder();
        },
        manageTagsPlaceholder() {
            let noTags = this.parseCountries.find(c => !c.value);
            this.tagInputPlaceholder = noTags ? this.$t('Template.AvailableCountryPlaceholder') : null;
        },
        manageUnsavedInfo() {
            // TODO: manage also disableSaveSmsBodyButton
            let modified = !(this.disableSaveInfoButton && this.disableSaveCountryButton && this.disableSaveBodyButton);

            if (modified) {
                // open unsaved changes warning modal
                this.unsavedTemplateInfoModal = true;

                // leave open the template edit modal
                this.templateModal = true;
            } else {
                this.unsavedTemplateInfoModal = false;
                this.templateModal = false;
            }
        },
        async getTemplateContent(template) {
            this.templateContent = JSON.parse(JSON.stringify(template.body));
            this.templateContentType = template.type;
            this.landingPageURL = template.landing_page_url;
            this.landingPageId = template.landing_page_id;

            if(template.languages) {
                this.langOptions = this.templateContent.map((temp) => { return { value: temp.language, label: this.isoLanguages[temp.language] } });
            } else {
                this.langOptions = [];
            }

            this.assignPreviewLanguage();

            this.currentTemplateContent = JSON.parse(JSON.stringify(this.templateContent));

            this.reloadPreview();

            this.$eventBus.$emit('loading', false)
        },
        async checkDuplicates() {
            if(!this.$refs.imgFile.files || !this.$refs.imgFile.files[0])  return;

            this.newImg = { file: this.$refs.imgFile.files[0], name: this.$refs.imgFile.files[0].name };

            // This function displays a modal to prevent replacing images with the same name by error
            let index = this.repo.findIndex(img => img.name === this.newImg.name);
            if(index > -1) {
                this.irreversibleOverrideImageModal = true;
                return;
            } else {
                await this.uploadImg();
            }
        },
        async uploadImg() {
            this.$eventBus.$emit('loading', true)
            let img = new FormData();
            img.append('imgfile', this.newImg.file);
            templateService.uploadImg(img).then((image) => {
                let index = this.repo.findIndex(img => img.name === this.newImg.name);
                this.$emit('repository', index, { name: this.newImg.name, size: image.data.size, icon: image.data.icon });
            }, (err) => {
                console.log("TemplateModal - uploadImg - error", err);
                this.$eventBus.$emit('show-alert', { title: this.$t('Template.ImageFormatUnsupported'), variant: 'danger'})
            }).finally(() => {
                this.$eventBus.$emit('loading', false)
                this.newImg = {};
            });
        },
        copyTemplateTag() {
            if (!navigator.clipboard) {
                this.fallbackCopyTemplateTag(this.selectedTag);
                return;
            }

            navigator.clipboard.writeText(this.selectedTag).then(() => {
                this.$eventBus.$emit('show-alert', { title: this.$t('General.CopiedInClipboard', { param: this.selectedTag }), variant: 'success'});
                this.selectedTag = null;
            }, function(err) {
                this.$eventBus.$emit('show-alert', { title: this.$t('General.CopiedInClipboardError', { param: this.selectedTag }), variant: 'danger'})
                console.error('Template Editor: Navigator.clipboard - Could not copy text', err);
            });
        },
        fallbackCopyTemplateTag(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            let successful = null;
            try {
                successful = document.execCommand('copy');
                
            } catch (err) {
                console.error('Template Editor: Document.execCommand - Could not copy text', err);
            } finally {
                if (successful) {
                    this.$eventBus.$emit('show-alert', { title: this.$t('General.CopiedInClipboard', { param: this.selectedTag }), variant: 'success'})
                    this.selectedTag = null;
                } else {
                    this.$eventBus.$emit('show-alert', { title: this.$t('General.CopiedInClipboardError', { param: this.selectedTag }), variant: 'danger'})
                }
            }
            document.body.removeChild(textArea);
        },
        confirmDeleteTitle() {
            return this.$i18n.t("Template.ConfirmDeleteTitle");
        },
        confirmDeleteMessage() {
            return this.$i18n.t("Template.ConfirmContentDeleteMessage");
        },
        confirmDeleteTemplateLanguage(language) {
            if(this.isOnlyLanguage) return;
            this.confirmDeleteRef = this.$refs['confirmDeleteBtn_'+language]

            this.templateLangToDelete = language;
            this.confirmModal = true;
            this.confirmModalTitle = this.$t('Template.ConfirmDeleteTitle');
            this.confirmModalDescription = this.$t('Template.ConfirmContentDeleteMessage');
            this.confirmModalCallback = this.deleteTemplateContentLanguage;
        },
        async addTemplateLanguage() {
            try {
                let options = this.templateContentType == this.TEMPLATE_TYPE_SMS? 
                    { language: this.newTemplateLanguage, sender: 'noreply@cgphishing.com', subject : 'GoPhish email subject'} : 
                    { language: this.newTemplateLanguage };
                await templateService.createTemplateContent(this.templateCompanyId, this.templateId, options);
                this.alertMessage = 'Template.TemplateContentCreated';
                this.alertVariant = 'success';

                let template = await templateService.getTemplateDetails(this.templateCompanyId, this.templateId);
                if(template && template.data) {
                    this.template.body = template.data;
                    this.template.languages = template.data.length;
                    this.getTemplateContent(this.template);
                }

                this.$emit('reload', this.templateId);
            } catch (error) {
                this.alertMessage = 'Template.TemplateContentError';
                this.alertVariant = 'danger';
                if (error.response && error.response.status == 409) {
                    console.log("error.response.status", error.response.status);
                    this.alertMessage = 'Template.DuplicateLanguageError';
                }
            } finally {
                this.newTemplateLanguage = null;
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage), variant: this.alertVariant})
            }
        },
        async deleteTemplateContentLanguage() {
            if (!this.templateLangToDelete) return;

            try {
                await templateService.deleteTemplateContent(this.templateCompanyId, this.templateId, this.templateLangToDelete);
                this.alertMessage = 'Template.TemplateContentDeleted';
                this.alertVariant = 'success';

                let template = await templateService.getTemplateDetails(this.templateCompanyId, this.templateId);
                if(template && template.data) {
                    this.template.body = template.data;
                    this.template.languages = template.data.length;
                    this.getTemplateContent(this.template);
                }

                this.$emit('reload', this.templateId);
            } catch (error) {
                this.alertMessage = 'Template.TemplateContentDeleteError';
                this.alertVariant = 'danger';
            } finally {
                this.newTemplateLanguage = null;
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage), variant: this.alertVariant})
            }
        },
        checkPlaceholders(text) {
            let cleanText = text.replace(/{{\.LastName}}/gm, '')
                .replace(/{{\.FirstName}}/gm, '')
                .replace(/{{\.Email}}/gm, '')
                .replace(/{{\.Tracker}}/gm, '')
                .replace(/{{\.TrackingURL}}/gm, '')
                .replace(/{{\.From}}/gm, '')
                .replace(/{{\.Position}}/gm, '')
                .replace(/{{\.RId}}/gm, '')
                .replace(/{{\.BaseURL}}/gm, '')
                .replace(/{{\.URL}}/gm, '')
                .replace(/{{\.CompanyLogoAsDataURL}}/gm, '')
                .replace(/{{\.Img=.*}}/gm, '')
                .replace(/{{\.QRCode}}/gm, '')
                .replace(/{{\.CurrentDate([+-])([0-9]+)}}/gm, '');

            return cleanText.match(/{{\.*[a-zA-Z0-9\s][^}]*}}/);
        },
        compilePlaceholders(text, template, highlight) {
            let landingPage = this.landingPageURL || '#';
            let baseURL = this.companyData.gp_landing_url;
            let logo = this.companyData.company.logo;

            // Extract baseURL from landing_page_url
            if(this.landingPageURL) {
                let firstIndex = (this.landingPageURL.indexOf('://') > -1 ) ? this.landingPageURL.indexOf('://') + 3 : this.landingPageURL.length;
                baseURL = this.landingPageURL.substr(0, firstIndex);
                let secondUrlPart = this.landingPageURL.substr(firstIndex);
                let lastIndex = (secondUrlPart.indexOf('/') > -1) ? secondUrlPart.indexOf('/') : secondUrlPart.length;
                baseURL += secondUrlPart.substr(0, lastIndex);
            }

            let finalText = text.replace(/{{\.LastName}}/gm, `{{.FirstName}}`)
                .replace(/{{\.FirstName}} {{\.FirstName}}/gm, `{{.FirstName}}`) 
                .replace(/{{\.FirstName}}/gm, (highlight) ? `<span title="${this.$i18n.t('Campaigns.PlaceholderFirstname')}" class="placeholder-card">${this.$profile.firstname}</span>`: this.$profile.firstname)
                .replace(/{{\.Email}}/gm, (highlight) ? `<span title="${this.$i18n.t('Campaigns.PlaceholderEmail')}" class="placeholder-card">${this.$profile.email}</span>`: this.$profile.email)
                .replace(/{{\.Tracker}}/gm, '')
                .replace(/{{\.TrackingURL}}/gm, '')
                .replace(/{{\.From}}/gm, template.sender)
                .replace(/{{\.Position}}/gm, '')
                .replace(/{{\.RId}}/gm, '')
                .replace(/{{\.Img=(\S.*?)\.(jpg|jpeg|png)}}/gi, (match, name, ext) => {
                    return `${this.companyData.img_url}/${name}.${ext}`;
                })
                .replace(/{{\.BaseURL}}/gm, baseURL)
                .replace(/{{\.CompanyLogoAsDataURL}}/gm, logo)
                .replace(/{{\.URL}}/gm, this.templateContentType == this.TEMPLATE_TYPE_EMAIL ? landingPage + '/?rid=staticlp' : '<a href="' + landingPage + '/?rid=staticlp">' + landingPage + '/?rid=staticlp</a>')
                .replace(/{{\.QRCode}}/gm, QRCode.value)
                .replace(/{{\.CurrentDate([+-])([0-9]+)}}/g, (match, symbol, days) => {
                    let date = symbol === "+" ? this.$dateTime.now().plus({days}) : this.$dateTime.now().minus({days});
                    return this.$dateTime.fromISO(date).toFormat('dd/MM/yyyy')})
                .replace(/{{\.*[a-zA-Z0-9\s][^}]*}}/gm, (match) => { 
                    return (highlight) ? `<span title="${this.$i18n.t('Template.WrongPlaceholderError')}" class="placeholder-card-error">${match}</span>`: '<invalid-placeholder>' 
                });

            return finalText;
        },
        compilePreview(templateContent, highlightSearch, language, type) {
            return new Promise((resolve, reject) => {
                try {
                    let template = templateContent.filter((t) => { return t.language == language; });
                    if(template.length == 0) {
                        template = templateContent.filter((t) => { return t.language == this.$profile.language; });
                        if(template.length == 0 && this.companyData && this.companyData.company) {
                            template = templateContent.filter((t) => { return t.language == this.companyData.company.default_language; });
                        }
                        if(template.length == 0) {
                            template = templateContent.filter((t) => { return t.language == 'en'; });
                            if(template.length == 0) {
                                template = templateContent;
                            }
                        }
                    }
                    
                    template = template[0];

                    if(typeof template == 'undefined' || typeof template.body == 'undefined') {
                        return resolve('<i class="far fa-edit"></i>');
                    }

                    let highlightedBody = template.body;
                    if (this.searchText && highlightSearch) {
                        var regEx = new RegExp("(" + this.searchText + ")(?!([^<]+)?>)", "gi");
                        highlightedBody = template.body.replace(regEx, "<span style='background: #ffc871;'>$1</span>")
                    }

                    if(type && type == this.TEMPLATE_TYPE_SMS) {
                        var senderRegex = new RegExp(/<cgsender>(.*)<\/cgsender>/);
                        var sender = template.body ? senderRegex.exec(template.body) : null;

                        this.smsSender = sender ? sender[1] : '';
                    }

                    this.emailSubject = template.subject;
                    this.emailSender = template.sender;

                    // highlightSearch is also used as a flag to identify the content code
                    let finalBody = !highlightSearch? template.body : this.compilePlaceholders(highlightedBody, template, true);

                    // In case of SMS Template type, remove these tags from Template Editor too
                    if(this.templateType && this.templateType === this.TEMPLATE_TYPE_SMS) {
                        finalBody = finalBody.replace(/<sms>/gm, '').replace(/<cgtelephone>.*$/gm, '');
                    }
                    resolve(finalBody);
                } catch (error) {
                    reject(error);
                }
            })
        },
        loadHints() {
            if (this.campaignId || this.isReadOnly) return;
            this.$eventBus.$emit('loading', true)

            templateService.getTemplateHints(this.templateCompanyId, this.templateId, this.previewLanguage, this.hintTable.filter, this.hintTable.currentPage, this.hintTable.perPage, this.hintTable.sortBy, this.hintTable.sortDesc)
                .then(result => {
                    this.hintsList = result.data.hints || [];
                }).catch((error) => {
                    console.error("TemplateModal - load hints error", error);
                }).finally(() => {
                    this.$eventBus.$emit('loading', false)
                });
        },
        showModalCreateHint() {
            this.hintData.item = {};
            Vue.set(this.hintData, 'title', 'Template.NewHint');
            
            let langs = [...this.langOptions];
            langs.unshift({ value: null, label: this.$i18n.t('General.SelectOption') });
            this.hintData.item.language = this.previewLanguage;

            Vue.set(this.hintData.fields.find(i => i.id === 'hint_id'), 'display', false);
            Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'display', true);
            Vue.set(this.hintData.fields.find(i => i.id === 'language'), 'options', langs);

            this.$refs.newHintModal.show(true); 
            this.newHintModal = true;
        },
        editHint(record) {
            this.hintData.item = record;
            Vue.set(this.hintData, 'title', 'Template.EditHint');

            Vue.set(this.hintData.fields.find(i => i.id === 'hint_id'), 'display', true);
            Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'display', false);
            Vue.set(this.hintData.fields.find(i => i.id === 'language'), 'options', this.langOptions);

            this.$refs.newHintModal.show(true); 
            this.newHintModal = true;
        },
        async updateHint(hint) {
            this.$eventBus.$emit('loading', true)
            let hintPromise = null;
            let msgOK = 'Template.HintCreated';
            let msgErr = 'Template.ErrorCreateHint';

            hint.priority = hint.priority || null;
            if(hint.hint_id) {
                hintPromise = templateService.updateTemplateHint(this.templateCompanyId, this.templateId, hint.hint_id, hint);
                msgOK = 'Template.HintUpdated';
                msgErr = 'Template.ErrorUpdateHint';
            } else {
                if(hint.icon) {
                    hint.body = '<table><tr><td style="vertical-align:top"><img width="40" src="' + this.config.defaults.hint.icon_path + '/' + hint.icon + '" alt="" /></td><td>' + hint.body + '</td></tr></table>';
                }

                hintPromise = templateService.createTemplateHint(this.templateCompanyId, this.templateId, hint);
            }

            hintPromise.then(() => {
                console.debug("Template - updateHint success", hint);
                this.alertMessage = msgOK;
                this.alertVariant = 'success';
                this.loadHints();

                this.templateContent.forEach((t) => {
                    if(!hint.hint_id && t.language == hint.language) {
                        t.hints += 1;
                    }
                })

                this.$emit('reload', this.templateId, 'reloadHint', 'hints', JSON.parse(JSON.stringify(this.templateContent)));
            }).catch((error) => {
                console.error("Template - updateHint error", hint, error);
                this.alertMessage = msgErr;
                this.alertVariant = 'danger';
            }).finally(() => {
                this.$eventBus.$emit('loading', false)
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage), variant: this.alertVariant})

                this.$refs.newHintModal.show(false)
                this.newHintModal = false
            });
        },
        confirmDeleteHint(hint) {
            this.hintToDelete = hint;

            this.confirmModal = true;
            this.confirmModalTitle = this.$t('Template.ConfirmDeleteTitle');
            this.confirmModalDescription = this.$tc('Template.ConfirmDeleteMessage', 1, { param: hint.hint_id });
            this.confirmModalCallback = this.deleteHint;
        },
        deleteHint() {
            if (!this.hintToDelete) return;

            const hintId = this.hintToDelete.hint_id;

            this.$eventBus.$emit('loading', true)
            templateService.deleteTemplateHint(this.templateCompanyId, this.templateId, hintId).then(() => {
                console.debug("Template - deleteHint success", hintId);
                this.alertMessage = 'Template.HintDeleted';
                this.alertVariant = 'success';
                this.loadHints();

                this.templateContent.forEach((t) => {
                    if(t.language == this.hintToDelete.language) {
                        t.hints -= 1;
                    }
                })

                this.$emit('reload', this.templateId, 'reloadHint', 'hints', JSON.parse(JSON.stringify(this.templateContent)));
            }).catch((error) => {
                console.error("Template - deleteHint error", hintId, error);
                this.alertMessage = 'Template.ErrorDeleteHint';
                this.alertVariant = 'danger';
            }).finally(() => {
                this.$eventBus.$emit('loading', false)
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage), variant: this.alertVariant});
            });
        },
        targetEmailModal(singleLanguage) {
            console.debug("Template - targetEmailModal - ask Target mail for Test Attack Template ", this.templateName);
            this.testTemplateTrigger = singleLanguage && this.$refs.testTemplateLangBtn || this.$refs.testTemplateBtn;
            this.singleLanguage = singleLanguage;
            this.emailDomains = this.companyData && this.companyData.company && this.companyData.company.email_domain && this.companyData.company.email_domain.split(/,| /).filter( domain => domain!='' );
            this.validDomain = {}
            if(this.emailDomains){
                Vue.set( this.validDomain, "count", this.emailDomains.length,)
                Vue.set( this.validDomain, "domain", this.emailDomains.join(', '))
            }
        
            if(this.templateType != this.TEMPLATE_TYPE_SMS) {
                this.targetEmail = this.$profile.email;
            } else {
                this.targetEmail = `noreply@cgphishing.com`;
            }

            this.testTemplateAttackModal = true;
        },
        testAttack() {
            this.$eventBus.$emit('loading', true)
            
            // Create an actual test Campaign whose params are as defined in config.json settings, targetEmail as only target and template_id as only template
            // In case of SMS template type, additional data are recipient's telephone number and template type
            let promise = null;
            if (this.campaignId) {
                promise = campaignService.testAttack(this.companyData.company.company_id, this.campaignId, this.templateId, this.targetEmail, this.targetName, this.targetNumber, this.templateType, this.previewLanguage, this.companyData.company.company_id)
            } else {
                promise = templateService.testAttack(this.templateCompanyId, this.templateId, this.targetEmail, this.targetName, this.targetNumber, this.templateType, this.previewLanguage, this.companyData.company.company_id, this.singleLanguage)
            }
            
            this.alertParams = {
                template: this.templateType == TemplateType.SMS? this.template.name : this.emailSubject,
                recipient: this.templateType == TemplateType.SMS? this.targetNumber : this.targetEmail,
            };

            promise.then(() => {
                this.$eventBus.$emit('show-alert', { title: this.$t('Campaigns.TestTemplateSuccess', this.alertParams), variant: 'success'})
            }).catch(() => {
                this.$eventBus.$emit('show-alert', { title: this.$t('Campaigns.TestTemplateError', this.alertParams), variant: 'danger'})
            }).finally(() => {
                this.$eventBus.$emit('loading', false)
            });
        },
        async downloadTemplateHTML() {
            const blob = new Blob([this.previewLg], { type: 'text/html' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = this.template.name + ".html";
            link.click();
            URL.revokeObjectURL(link.href);
        },
        assignPreviewLanguage(languageSet) {
            // Find the Landing page version written for locale language setting
            if (!languageSet) {
                this.previewLanguage = this.templateContent.find(element => element.language == this.$i18n.locale) ? this.$i18n.locale : null;
                if (this.previewLanguage == null) {
                    // If the 'locale' version does not exist, try displaying the Template in English, otherwise pick the first available language
                    this.previewLanguage = this.templateContent.find(element => element.language == "en") ? "en" : this.templateContent[0].language;
                }

                this.loadHints();
            }
        },
        async checkBodyPlaceholder(code) {
            let savedData = this.templateContent.find(tc => { return tc.language == this.previewLanguage });

            let invalidPlaceholder = this.checkPlaceholders(code);
            if(invalidPlaceholder && invalidPlaceholder.length > 0) {
                let invalidPlaceholderString = invalidPlaceholder.join(', ');
                this.alertMessage = 'Template.InvalidPlaceholderError';
                this.alertVariant = 'danger';
                this.alertParams = { placeholder: invalidPlaceholderString };
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage, this.alertParams), variant: this.alertVariant})
                return;
            }

            if(savedData.body != code) {
                if (this.saveTimeout) {
                    clearTimeout(this.saveTimeout);
                }
                this.saveTimeout = setTimeout(this.updateTemplateBody, 1000, code);
            }
        },
        updateTemplateBody(code) {
            this.isLoaded = false;
            this.templateContent.forEach(tc => { if(tc.language == this.previewLanguage) { 
                tc.body = code;
            } });
            this.reloadPreview();
        },
        updateTemplateMetadata() {
            this.templateContent.map(tc => { if(tc.language == this.previewLanguage) { 
                tc.sender = this.emailSender;
                tc.subject = this.emailSubject;
                tc.smsSender = this.smsSender;
            } });
        },
        async reloadPreview() {
            this.previewLg = await this.compilePreview(this.templateContent, true, this.previewLanguage, this.templateType);
            this.previewLg = this.$options.filters.sanitizeRawHtml(this.previewLg, { transformTags: {
                'a': sanitizeHtml.simpleTransform('a', { name: 'landing-link' })
            }});
            this.codePreview = await this.compilePreview(this.templateContent, false, this.previewLanguage, this.templateType);
            if (this.$refs.templateEditorRef) this.$refs.templateEditorRef.refresh(this.codePreview);

            if(this.template.type == this.TEMPLATE_TYPE_SMS && this.previewLg) {
                this.previewLg =  `<div class="chat"><div class="message">`+ this.previewLg + `</div></div>`;
            }
        },
        saveTemplate() {
            let savedData = this.templateContent.find(t => { return t.language == this.previewLanguage });
            let previousData = this.currentTemplateContent.find(t => { return t.language == this.previewLanguage });

            savedData.sender = this.emailSender;
            savedData.subject = this.emailSubject;
            //savedData.body = this.$options.filters.sanitizeRawHtml(savedData.body);
            
            var smsRegex = new RegExp(/<sms>(.*)<\/sms>/);
            var smsBody = smsRegex.exec(savedData.body);

            if(this.template.type == this.TEMPLATE_TYPE_SMS && !smsBody) {
                savedData.body = `<sms>${savedData.body}<cgtelephone>{{.Position}}</cgtelephone><cgsender>${this.smsSender}</cgsender></sms>`;
            } else {
                savedData.body = savedData.body.replace(/<sms>(.*)<cgtelephone>{{.Position}}<\/cgtelephone>(<cgsender>(.*)<\/cgsender>){0,1}<\/sms>/, `<sms>$1<cgtelephone>{{.Position}}</cgtelephone><cgsender>${this.smsSender}</cgsender></sms>`);
            }

            if(JSON.stringify(previousData) === JSON.stringify(savedData)) {
                return;
            }

            templateService.updateTemplateContent(this.templateCompanyId, this.templateId, this.previewLanguage, savedData).then(() => {
                console.debug("TemplateModal - saveTemplate success", this.templateId);
                this.alertVariant = 'success';
                this.alertMessage = 'Template.TemplateUpdated';
                
                this.templateContent.forEach((template) => {
                    if(template.language == this.previewLanguage) {
                        template.sender = this.emailSender;
                        template.subject = this.emailSubject;
                        template.smsSender = this.smsSender;
                    } 
                })

                // Update saved Template content
                this.currentTemplateContent = JSON.parse(JSON.stringify(this.templateContent));

                this.$emit('reload', this.templateId, 'reloadBody', 'body', JSON.parse(JSON.stringify(this.templateContent)));
            }).catch((error) => {
                console.error("Template - saveTemplate error");
                this.alertVariant = 'danger';
                if(error.response.status == 400) {
                    this.alertMessage = 'Template.ErrorUpdateTemplateTooBig';
                } else {
                    this.alertMessage = 'Template.ErrorUpdateTemplate';
                }
            }).finally(() => {
                this.$eventBus.$emit('loading', false)
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage), variant: this.alertVariant})
            });
        },
        async copyTemplate() {
            let alertVariant = '';
            let alertMessage = '';
            try {
                await templateService.copyTemplate(this.templateCompanyId, this.templateId, { name: this.currentTemplate.name, companyId: this.copyTemplateCompanyId } );
                alertVariant = 'success';
                alertMessage = this.$t('Template.CopySuccess');
            } catch (error) {
                alertVariant = 'danger';
                alertMessage = this.$t('Template.CopyError');
            } finally {
                this.$emit('copied', alertMessage, alertVariant);
                this.templateModal = false;
            }
        },
        checkSubjectPlaceholder(data) {
            let invalidSubjectPlaceholder = this.checkPlaceholders(data);
            if(invalidSubjectPlaceholder && invalidSubjectPlaceholder.length > 0) {
                let invalidPlaceholderString = invalidSubjectPlaceholder.join(', ');
                this.alertMessage = 'Template.InvalidPlaceholderError';
                this.alertVariant = 'danger';
                this.alertParams = { placeholder: invalidPlaceholderString };
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage, this.alertParams), variant: this.alertVariant})
                return;
            } 
        },
        checkSMSSender(data) {
            var senderRegex = new RegExp(/[a-zA-Z0-9 .]{0,11}/);
            var sender = senderRegex.exec(data);

            if(data && (data.length > 11 || sender[0] !== data )) {
                this.$eventBus.$emit('show-alert', { title: this.$t('Template.ValidSender'), variant: this.alertVariant})
                return;
            } 
        },
        emailIFrameLoaded() {
            // Email iFrame and its elements will be available for manipulation only after this point
            this.addLandingPageLink('templatePreviewEmailIframe');
            this.isLoaded = true;
        },
        smsIFrameLoaded() {
            // SMS iFrame and its elements will be available for manipulation only after this point
            this.addIframeStyle();
            this.addLandingPageLink('templatePreviewIframe');
            this.isLoaded = true;
        },
        addIframeStyle() {
            // Add dynamic CSS to iFrame
            var frame = document.getElementById("templatePreviewIframe");
            let style = frame.contentWindow.document.createElement('style');
            style.type = 'text/css';
            style.appendChild(frame.contentWindow.document.createTextNode(`<style type='text/css'>  
                .chat {
                    width: 300px;
                    border: solid 1px #EEE;
                    padding: 10px;
                }
                .message {
                    border-radius: 20px;
                    padding: 8px 15px;
                    margin-right: 25%;
                    background-color: #eee;
                    position: relative;
                    width: fit-content;
                    font-family: "Avenir", Helvetica !important;
                }
                .message:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    left: -7px;
                    height: 20px;
                    width: 20px;
                    background: #eee;
                    border-bottom-right-radius: 15px;
                }
                .message:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: -10px;
                    width: 10px;
                    height: 20px;
                    background: white;
                    border-bottom-right-radius: 10px;
                }
                </style>`));
            frame.contentWindow.document.head.appendChild(style);
        },
        addLandingPageLink(iFrameName) {
            if(this.companyData && this.companyData.company) {
                var frame = document.getElementById(iFrameName);
                frame.contentWindow.document.body.onclick = (event) => {
                    if(event.target.name == "landing-link" || (event.target.closest( "a" ) ? event.target.closest( "a" ).name == "landing-link" : false )) {
                        this.openCompanyLandingPage(event);
                    }
                }
            }
        },
        async openCompanyLandingPage(event) {
            event.preventDefault();
            var landingData = await templateService.getTemplateLandingPage(this.templateCompanyId, this.templateId, this.previewLanguage, this.$currentCompany.company_id);
            var newPage = window.open("");

            let baseURL = this.companyData.gp_landing_url;

            // Extract baseURL from landing_page_url
            if(this.landingPageURL) {
                let firstIndex = (this.landingPageURL.indexOf('://') > -1 ) ? this.landingPageURL.indexOf('://') + 3 : this.landingPageURL.length;
                baseURL = this.landingPageURL.substr(0, firstIndex);
                let secondUrlPart = this.landingPageURL.substr(firstIndex);
                let lastIndex = (secondUrlPart.indexOf('/') > -1) ? secondUrlPart.indexOf('/') : secondUrlPart.length;
                baseURL += secondUrlPart.substr(0, lastIndex);
            }

            let finalLP = landingData.data.html.replace(/{{.BaseURL}}/gm, baseURL);

            newPage.document.write(finalLP);
            newPage.document.close();
        },
        updateTemplateBasicInfo() {
            this.$eventBus.$emit('loading', true)

            this.currentTemplate.country = this.parseCountries.map(c => c.value).join('#');

            let info = {
                name: this.currentTemplate.name,
                tags: this.currentTemplate.tags,
                landing_page_url: this.currentTemplate.landing_page_url,
                landing_page_id: this.currentTemplate.landing_page_id || null,
                difficulty: this.currentTemplate.difficulty,
                company_id: this.currentTemplate.company_id,
                type: Number(this.currentTemplate.type),
                has_attachment: this.currentTemplate.type != this.TEMPLATE_TYPE_SMS ? Number(this.currentTemplate.has_attachment) : null,
                attachment_id: this.currentTemplate.attachment_id,
                deleted: this.currentTemplate.deleted,
                forced: this.currentTemplate.forced,
                country: this.currentTemplate.country || null,
                current_company_id: this.companyData.company_id
            };

            templateService.updateTemplate(this.templateCompanyId, this.templateId, info).then(async () => {
                console.debug("Template - updateTemplateBasicInfo success", this.templateId);

                // Update landing page links in preview tab
                if(this.template.landing_page_url != this.landingPageURL && this.template.landing_page_url != '') {
                    this.landingPageURL = this.template.landing_page_url;
                }

                this.attrList.forEach(name => {
                    this.template[name] = this.currentTemplate[name];
                });

                this.reloadPreview();
                this.$emit('reload', this.templateId);

                this.alertVariant = 'success';
                this.alertMessage = 'Template.TemplateUpdated';
            }).catch((error) => {
                console.error("Template - updateTemplateBasicInfo error", this.templateId, error);
                this.alertVariant = 'danger';
                this.alertMessage = 'Template.ErrorUpdateTemplate';
            }).finally(() => {
                this.$eventBus.$emit('loading', false)
                this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage), variant: this.alertVariant})
            });
        },
        exportTemplate(){
            this.$eventBus.$emit('loading', true)
            templateService.exportTemplate(this.templateCompanyId, this.templateId)
                .then((response) => {
                    this.$methods.downloadBlob(response)
                })
                .catch((error) => {
                    console.error('Error Exporting Templates =>>', error)
                })
                .finally(() => {
                    this.$eventBus.$emit('loading', false)
                })
        }
    },
    async mounted() {
        // Retrieve all countries and languages
        const isoCountries = this.$isoCountriesLanguages.getCountries(this.$i18n.locale);
        this.isoCountryOptions = Object.keys(isoCountries).map((c) => { return { value: c, label: isoCountries[c]} });
        
        this.isoLanguages = this.$isoCountriesLanguages.getLanguages(this.$i18n.locale);
        this.isoLangOptions = Object.keys(this.isoLanguages).map((l) => { return { value: l, label: this.isoLanguages[l] } });
        
        templateService.getHintIcons().then((res) => {
            let iconArr = res && res.data || []
            Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'options', iconArr.map(i => { return { value: i.name, label: i.name, icon: i.icon} }));
        }).catch((error) => {
            console.log("TemplateModal - getHintIcons - error", error);
            Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'options', []);
        });

        let hintOptions = [ 
            {value: 'urgency', label: 'urgency'}, 
            {value: 'grammar', label: 'grammar'}, 
            {value: 'content', label: 'content'}, 
            {value: 'address', label: 'address'}, 
            {value: 'link', label: 'link'}, 
            {value: 'sender', label: 'sender'}, 
            {value: 'structure', label: 'structure'} 
        ];

        this.tagOptions = [
            { label: this.$i18n.t('General.LastName'), value: '{{.LastName}}' },
            { label: this.$i18n.t('General.FirstName'), value: '{{.FirstName}}' },
            { label: this.$i18n.t('General.Email'), value: '{{.Email}}' },
            { label: this.$i18n.t('General.Tracker'), value: '{{.Tracker}}' },
            { label: this.$i18n.t('General.TrackingURL'), value: '{{.TrackingURL}}' },
            { label: this.$i18n.t('General.Sender'), value: '{{.From}}' },
            { label: this.$i18n.t('General.Number'), value: '{{.Position}}' },
            { label: this.$i18n.t('General.RId'), value: '{{.RId}}' },
            { label: this.$i18n.t('General.BaseURL'), value: '{{.BaseURL}}' },
            { label: this.$i18n.t('General.URL'), value: '{{.URL}}' },
            { label: this.$i18n.t('General.CompanyLogo'), value: '{{.CompanyLogoAsDataURL}}', disabled: false },
            { label: this.$i18n.t('General.QRCode'), value: '{{.QRCode}}', disabled: false },
            { label: this.$i18n.t('General.Date'), value: '{{.CurrentDate+0}}', disabled: false }
        ];

        this.typeOptions = [
            { value: this.TEMPLATE_TYPE_EMAIL, label: this.$t('Analytics.TemplateTypes.Email') }, 
            { value: this.TEMPLATE_TYPE_SMS, label: this.$t('Analytics.TemplateTypes.Sms') },
            { value: this.TEMPLATE_TYPE_QRCODE, label: this.$t('Analytics.TemplateTypes.QRCode') },
            { value: this.TEMPLATE_TYPE_USB, label: this.$t('Analytics.TemplateTypes.USB') },
        ]
        
        this.tagOptions.unshift({ value: null, label: this.$i18n.t('General.Select') });

        Vue.set(this.hintData.fields.find(i => i.id === 'class'), 'options', hintOptions);

        let configdata = await phishingService.getConfig();
        this.config = configdata.data;

        if(!this.campaignId) {
            let companyListData = await phishingService.getPhishingCompanies();
            this.companyList = companyListData.data.sort((a, b) => { return ('' + a.name).localeCompare('' + b.name); });
        }
    },
    created() {
        this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
        this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;
        this.TEMPLATE_TYPE_QRCODE = TemplateType.QRCode;
        this.TEMPLATE_TYPE_USB = TemplateType.USB;

        this.attrList.push('name', 'tags', 'scope');
        this.attrList.push('landing_page_url', 'landing_page_id');
        this.attrList.push('difficulty', 'type', 'has_attachment', 'attachment_id', 'company_id', 'deleted', 'forced', 'country');
    }
};
</script>

<style lang="scss" scoped>
.placeholder-card {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #C2A7E4;
}
.placeholder-card:after {
    content: '*';
    color: #C2A7E4;
}
.placeholder-card-error {
    cursor: pointer;
    text-decoration: line-through;
    text-decoration-color: #b71c1c;
}
.placeholder-card-error:after {
    content: '*';
    color: #b71c1c;
}
.table-wrapper[data-v-00e63641] {
    overflow-x: inherit;
}
.tab-content {
  @apply overflow-auto ;
  &::-webkit-scrollbar {
    @apply appearance-none;
    // @apply w-3 #{!important};
    width: 0.75rem !important;
    @apply invisible;
  }
  &:hover, &:focus, &:active {
    &::-webkit-scrollbar {
      @apply visible
    }
    &::-webkit-scrollbar-track {
      @apply bg-transparent;
      @apply rounded-full;
      @apply my-5;
    }
    &::-webkit-scrollbar-thumb {
      @apply rounded-full;
      @apply bg-primary-300/50;
    }
    & {
      scrollbar-width: auto;
      scrollbar-color: rgb(131 135 154 / 0.5)  transparent;
      @apply scroll-my-3;
    }
  }

  .table-wrapper {
    @apply overflow-x-visible;
  }
}
</style>